.about-product {
  @include container--content;
  font-size: 1.8rem;
  color: $text;
  margin-top: -50px;
  @include media ("<phone") {
    font-size: 1.4rem; }

  &__title {
    @include gbackground(100%);
    display: inline-block;
    color: $white;
    text-transform: uppercase;
    font-size: 7.2rem;
    font-weight: 900;
    padding: 0 20px;
    margin-top: 0;
    margin-bottom: 30px;
    @include media ("<tablet") {
      font-size: 3.0rem;
      padding: 10px 15px; } }

  // start of 1st row of products
  &__products-1 {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: -30px;
    flex-wrap: wrap;
    @include media ("<desktop") {
      margin-bottom: 20px; }
    @include media ("<tablet") {
      flex-flow: column;
      align-items: center; } }

  .swiper {
    &-container {
      width: 100%;
      margin-top: 60px;
      @include media (">=tablet") {
        display: none; } }

    &-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      width: 100%;
      &:nth-child(2) {
        position: relative;
        top: 20px;
        @include media ("<phone") {
          top: 40px; } }
      p {
        text-align: center;
        margin: 40px 0; } }

    &-img-slide-1 {
      width: 248px;
      height: 360px;
      @include media ("<phone") {
        width: 206px;
        height: 299px; } }

    &-img-slide-2 {
      width: 165px;
      height: 354px;
      @include media ("<phone") {
        width: 125px;
        height: 268px; } }

    &-pagination {
      top: 310px;
      @include media (">phone") {
        top: 370px; }

      .swiper-pagination-bullet {
        width: 30%;
        height: 1px;
        margin: 0 5%;
        background-color: $text;
        border-radius: unset;
        &:focus {
          outline: none; }
        &::after {
          content: " ";
          padding: 15px;
          display: block;
          position: relative;
          bottom: 13px; }
        &-active {
          @include gbackground(100%); } } } }

  &__gum {
    &-wrapper {
      display: flex;
      width: 319px;
      @include media ("<tablet") {
        order: 2;
        flex-flow: column;
        align-items: center;
        margin-bottom: 60px; } }
    &-img {
      width: auto;
      height: 354px;
      margin-right: -15px;
      margin-left: -40px;
      flex: none;
      @include media ("<tablet") {
        margin: 0; }
      @include media ("<phone") {
        width: 125px;
        height: 268px; } }
    &-arrow-caption-wrapper {
      @include media ("<tablet") {
        margin-top: -40px;
        text-align: center; } }
    &-caption {
      display: inline-block;
      margin-top: 45px;
      margin-left: 70px;
      padding: 10px 0;
      @include media ("<tablet") {
        margin: 0;
        width: 100%; } }
    &-arrow {
      @include media ("<tablet") {
        display: none; } } }

  &__che {
    &-wrapper {
      align-self: baseline;
      // padding-top: 55px
      display: flex;
      width: 490px;
      margin-top: 65px;
      flex: none;
      // margin: 0 -50px
      @include media ("<large") {
        order: 1;
        margin-top: -105px; }
      @include media ("<tablet") {
        // margin-top: 65px
        display: none; } }
    &-arrow-caption-1 {
      margin-top: 25px; }
    &-arrow-1 {
 }      // margin-left: 55px
    &-caption-1 {
      display: inline-block;
      width: 130px;
      text-align: right;
      margin-left: -50px; }
    &-img {
      width: 248px;
      z-index: -1;
      margin-left: -95px;
      flex: none; }
    &-arrow-caption-2 {
      margin-top: 130px; }
    &-caption-2 {
      display: inline-block;
      width: 150px; }
    &-arrow-2 {
      margin-left: -20px; } }

  &__sticker {
    &-wrapper {
      position: relative;
      top: -45px;
      align-self: center;
      display: flex;
      width: 340px;
      @include media ("<large") {
        width: 390px; }
      @include media ("<tablet") {
        order: 3;
        flex-flow: column;
        align-items: center;
        margin-bottom: 60px; } }
    &-arrow-caption-wrapper {
      @include media ("<tablet") {
        order: 2;
        margin: 0; } }
    &-caption {
      display: inline-block;
      width: 135px;
      text-align: right;
      margin-left: -70px;
      margin-bottom: 10px;
      @include media ("<large") {
        margin-left: 0; }
      @include media ("<tablet") {
        text-align: center; // here or on wrapper?
        width: 190px; }
      @include media ("<phone") {
        width: 175px; } }
    &-arrow {
      @include media ("<large") {
        margin-left: 70px; }
      @include media ("<tablet") {
        display: none; } }
    &-circle {
      height: 200px;
      width: 200px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 9px 20px #CCC;
      display: flex;
      flex-shrink: 0;
      // flex-wrap: wrap
      // align-content: center
      flex-flow: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      font-size: 1.1rem;
      @include media ("<tablet") {
        order: 1;
        margin-bottom: 35px;
        flex-basis: auto; } }
    &-text {
      display: inline-block;
      width: 115px;
      color: $black; }
    &-hashtag {
      font-weight: 900;
      font-size: 1.4rem;
      margin: 10px 0; }
    &-tag {
      font-size: 1rem;
      color: $white;
      background-color: $black;
      padding: 0 3px; }
    &-smaller-text {
      font-size: 1rem;
      margin-bottom: 5px; } }

  //start of 2nd row of products
  &__products-2 {
    display: flex;
    // align-items: baseline
    justify-content: space-between;
    // margin-right: 90px
    flex-wrap: wrap;
    @include media ("<large") {
      justify-content: space-around; }
    @include media ("<tablet") {
      flex-flow: column;
      align-items: center; } }

  &__box {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 521px;
      @include media ("<tablet") {
        width: 341px;
        flex-flow: column;
        align-items: center;
        margin-bottom: 60px; }
      @include media ("<phone") {
        width: 100%; } }
    &-arrow {
      margin-left: 70px;
      @include media ("<tablet") {
        display: none; } }
    &-caption-img-wrapper {
      display: flex;
      @include media ("<tablet") {
        flex-flow: column;
        align-items: center; } }
    &-caption-wrapper {
      width: 130px;
      margin-right: 50px;
      @include media ("<tablet") {
        order: 2;
        margin: 0;
        width: 100%;
        text-align: center; } } // here or on span?
    &-caption {
      display: inline-block; }
    &-img {
      width: 341px;
      height: auto;
      margin-top: -10px;
      flex: none;
      @include media ("<tablet") {
        order: 1;
        margin-bottom: 5px; }
      @include media ("<phone") {
        width: 266px;
        height: 110px; } } }

  &__clips {
    &-wrapper {
      display: flex;
      align-items: center;
      width: 470px;
      @include media ("<tablet") {
        flex-flow: column;
        margin-bottom: 60px;
        width: 191px; } }
    &-img {
      width: 191px;
      flex: none;
      @include media ("<tablet") {
        margin-bottom: 25px; } }
    &-arrow-caption-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 279px;
      @include media ("<tablet") {
        width: 100%;
        justify-content: center; } }
    &-caption {
      display: inline-block;
      width: 143px;
      text-align: right;
      @include media ("<tablet") {
        text-align: center; } } // here or on wrapper?
    &-arrow {
      @include media ("<tablet") {
        display: none; } } } }
