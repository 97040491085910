.submenu {
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  padding-top: 95px;
  padding-bottom: 10px;
  z-index: 4;
  background-color: $bg-color;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(180deg, $bg-color, rgba($white, 0.001)); }
  @include media("<huge") {
    padding-top: 140px; }
  @include media("<desktop") {
    padding-bottom: 40px;
    //&:after
 }    //top: calc(100% + 30px)
  @include media("<tablet") {
    padding-top: 75px; }

  &__wrapper {
    position: relative;
    @include container--content;
    padding-left: 147px;
    display: flex;
    @include media("<desktop") {
      max-height: 58px;
      padding: 0;
      border: 1px solid;
      border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
      border-image-slice: 1; }
    @include media("<tablet") {
      justify-content: space-between; } } }

.submenu__section + .submenu__section {
  margin-left: 20px;
  @include media("<large") {
    margin-left: 10px; }
  @include media("<desktop") {
    margin-left: 0; } }

.submenu__section {
  display: flex;
  align-items: flex-start;
  padding: 16px 25px;
  color: transparent;
  @include gbackground(200%, 50%);
  -webkit-background-clip: text;
  background-clip: text;
  background-position: 100% 0;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
  border-image-slice: 1;
  transition: box-shadow $transition, color $transition, flex-grow $transition, background-position $transition;
  cursor: pointer;
  &:hover {
    background-position: 0 0; }
  &:active, &:focus {
    outline: none;
    box-shadow: 0 0 3px 0 rgba($black ,0.5); }
  @include media("<desktop") {
    border: 0; }
  @include media("<tablet") {
    padding: 12px;
    flex-basis: 33%;
    align-items: center;
    justify-content: center;
    &:active, &:focus {
      outline: none;
      box-shadow: none; } }

  .submenu__title {
    display: block;
    line-height: 220%;
    &:hover {
      .submenu__title-extra {
        background-position: 0 0; }
      .submenu__title-main {
        background-position: 0 0; } }
    @include media("<tablet") {
      line-height: 100%; } }

  .submenu__title-extra {
    display: block;
    white-space: nowrap;
    @include subtitle(1.8rem);
    color: transparent;
    font-weight: 400;
    max-height: unset;
    @include gbackground(200%, 50%);
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 100% 0;
    transition: background-position $transition;
    @include media("<large") {
      font-size: 1.6rem; }
    @include media("<desktop") {
      display: none; } }


  .submenu__title-main {
    display: block;
    @include subtitle(3rem);
    color: transparent;
    font-weight: 900;
    letter-spacing: 3px;
    @include gbackground(200%, 50%);
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 100% 0;
    transition: background-position $transition;
    @include media("<large") {
      font-size: 2.4rem; }
    @include media("<tablet") {
      font-size: 1.2rem; } }

  .submenu__title-main--desktop {
    @include media("<desktop") {
      display: none; } }
  .submenu__title-main--mobile {
    display: none;
    @include media("<desktop") {
      display: block; } }


  .submenu__subsections {
    display: none;
    max-width: 0;
    padding: 0;
    pointer-events: none; } }


.submenu__section--constructor.submenu__section--active {
  padding: 16px 20px; }


.submenu__section--active {
  padding: 16px 35px;
  flex-grow: 1;
  color: $white;
  @include gbackground(100%);
  border-image-source: none;
  border-image-slice: 0;
  border: 0;
  background-clip: border-box;
  @include media("<desktop") {
    justify-content: center; }
  @include media("<tablet") {
    flex-grow: 0;
    padding: 10px; }

  .submenu__title-extra, .submenu__title-main {
    color: $white; }

  .submenu__title-main {
    line-height: 100%;
    margin-right: 15px;
    @include media("<tablet") {
      margin-right: 0; } }

  .submenu__subsections {
    margin: 16px 0 0 auto;
    display: flex;
    max-width: unset;
    opacity: 1;
    transition: max-width $transition*10;
    pointer-events: all;
    @include media("<desktop") {
      margin: 0;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3; } }

  .submenu__subsection-item {
    @include subtitle(1.8rem);
    font-weight: 800;
    letter-spacing: 1.8px;
    white-space: nowrap;
    color: $white;
    text-decoration: none;
    opacity: 0.5;
    transition: opacity $transition, max-width $transition;
    &:hover {
      opacity: 1; }
    @include media("<desktop") {
      color: $text;
      font-size: 1.6rem; }
    @include media("<tablet") {
      font-size: 1.4rem; } }

  .submenu__subsection-item--active {
    opacity: 1; }

  .submenu__subsection-item + .submenu__subsection-item {
    position: relative;
    margin-left: 30px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: - 15px;
      transform: translateY(-50%);
      width: 1px;
      height: calc(100% - 8px);
      background-color: $white; }
    @include media("<desktop") {
      &:before {
        background-color: $text; } } } }

.submenu--minified {
  .submenu__wrapper {
    justify-content: space-between;
    .submenu__section {
      flex-grow: 0;
      align-items: center;
      .submenu__title-extra {
        max-height: 0;
        max-width: 0;
        overflow: hidden; }
      .submenu__subsections {
        max-height: 0;
        max-width: 0;
        overflow: hidden; } } } }

.submenu__section--disabled {
  pointer-events: none;
  opacity: 0.7;
  filter: grayscale(1); }
