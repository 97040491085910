.accordion {
  &__item {}
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    border-image-slice: 1;
    cursor: pointer;
    &--active {
      @include gbackground(100%);
      .accordion__title {
        color: $white; }
      .accordion__btn {
        background: transparent; }
      .accordion__arrow {
        fill: $white;
        transform: scaleY(-1); } }

    &--active ~ .accordion__content {
      max-height: 1000px;
      transition: max-height $transition*5; } }

  &__title {
    padding: 13px; }

  &__btn {
    @include gbackground(100%);
    color: $white;
    padding: 13px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include media ("<tablet") {
      background: transparent;
      justify-content: flex-end; } }

  &__btn-action {
    margin: 0 10px;
    @include media ("<tablet") {
      display: none; } }

  &__arrow {
    fill: $white;
    align-self: center;
    margin: 0 10px;
    transition: transform $transition;
    @include media ("<tablet") {
      fill: $black;
      align-self: flex-end; } }

  &__content {
    max-height: 0;
    overflow: hidden;
    margin-left: 5px;
    transition: max-height $transition;
    @include media ("<tablet") {
      max-width: 100%; }
    ol {
      list-style-position: inside;
      padding: 0;
      margin: 30px 0;
      li {
        margin-bottom: 3.0rem; } } } }
