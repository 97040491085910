.orb-list {

  &__flex-column {
    display: flex;
    flex-flow: column;
    @include media ("<tablet") {
      margin-bottom: -40px; }
    .orb-list__item {
      align-items: baseline;
      @include media ("<tablet") {
        margin-bottom: 40px;
        align-items: center; } } }

  &__wrap {
    display: flex;
    justify-content: space-between;
    @include media ("<tablet") {
      justify-content: space-evenly; }
    span {
      color: $text;
      font-size: 1.8rem;
      font-weight: 600;
      text-transform: uppercase;
      @include media ("<desktop") {
        font-size: 1.5rem;
        margin: 0 5px; }
      @include media ("<=tiny") {
        font-size: 1.2rem; } } }

  &__item {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 30px; }

  &__svgs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 145px;
    position: relative; }

  &__svg-bg {
    fill: url(#linear-grad-4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }

  &__svg-img {
    fill: $white;
    z-index: 0;
    margin-top: -15px; } }
