.landing-2 {
  &__header-slider-wrapper {
    display: flex;
    align-items: flex-end;
    margin: 50px -240px 0;
    @include media("<large") {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start; }
    @include media("<desktop") {
      margin: unset; } }

  &__header-slider-controls {
    display: flex;
    flex-flow: column nowrap;
    max-width: 50px;
    margin-bottom: 150px;
    z-index: 3;
    @include media("<large") {
      flex-flow: row nowrap;
      order: 1;
      width: 60%;
      max-width: unset;
      margin-bottom: 0; }
    @include media("<desktop") {
      width: 100%;
      max-width: unset; } }

  &__header-slider-pagination {
    font-size: 1.8rem;
    font-weight: 900;
    color: $main-link;
    text-transform: uppercase;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity $transition;
    svg {
      opacity: 0;
      fill: $main-link;
      transition: fill $transition, transform $transition, opacity $transition; }
    &:hover {
      opacity: 0.9;
      svg {
        transform: translateX(10px); } }
    &--active {
      opacity: 1;
      svg {
        opacity: 1; } }
    @include media("<large") {
      text-shadow: 0 0 3px $white, 0 0 3px $white, 0 0 3px $white, 0 0 3px $white, 0 0 3px $white, 0 0 3px $white; }
    @include media("<desktop") {
      max-width: 153px; }
    @include media("<tablet") {
      max-width: 50px;
      font-size: 1.2rem; }
    @include media("<tablet"); }

  &__header-slider-pagination + &__header-slider-pagination {
    margin-top: 30px;
    @include media("<large") {
      margin-top: unset;
      margin-left: 80px; }
    @include media("<desktop") {
      margin-left: 50px; }
    @include media("<tablet") {
      margin-left: 50px; } }


  &__header-slider {
    z-index: 1;
    max-width: 1000px;
    width: 100%;
    @include media("<desktop") {
      margin-top: 26px;
      margin-bottom: 9px;
      max-width: 100%;
      width: 100%;
      height: 200px; }



    .swiper-wrapper {
      display: flex;
      align-items: flex-end;
      @include media("<desktop") {
        max-width: 100%;
        width: 100%; } }



    .swiper-slide {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: flex-end;
      padding-left: 50px;
      img {
        max-width: 100%; }
      @include media("<huge") {
        padding-left: 30px;
        img {
          width: 100%;
          max-width: 700px; } }
      @include media("<large") {
        img {
          width: 100%;
          max-width: 600px; } }
      @include media("<desktop") {
        justify-content: center;
        padding-left: 0;
        img {
          object-fit: contain;
          max-height: 100%;
          max-width: 500px; } } }


    .slide-small {
      margin-bottom: 0; } } }
