.main-menu {
  position: fixed;
  width: 100%;
  transition: background-color $transition;
  z-index: 11;
  background-color: $bg-color;
  padding-bottom: 10px;
  &:after {
    opacity: 1;
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(180deg, $bg-color, rgba($white, 0.001));
    transition: opacity $transition; }

  &--no-after {
    &:after {
      content: none; } }

  &__container {
    position: relative;
    @include container();
    display: flex;
    align-items: flex-start;
    z-index: 5;
    @include media("<huge") {
      flex-flow: row wrap; }
    @include media("<tablet") {
      padding: 15px 30px 5px;
      justify-content: space-between;
      align-items: center; } }

  &__logo {
    margin-left: 25px;
    margin-top: 30px;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.8rem;
    color: $black;
    display: flex;
    transition: color $transition;
    @include media("<huge") {
      order: 1;
      margin-left: 5px; }
    @include media("<tablet") {
      margin: 0; } }
  &__logo-sup {
    font-size: 1.2rem; }


  &__wrapper {
    position: relative;
    margin-left: 49px;
    margin-top: 27px;
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    @include media("<huge") {
      margin-left: 0;
      order: 3;
      flex-basis: 100%; }
    @include media("<tablet") {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
      background-color: $white; } }

  &__wrapper--mob-active {
    display: flex;
    flex-flow: column nowrap;
    max-height: 1000px;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100) - 54px);
    padding: 0 10px 35px;
    overflow: visible;
    z-index: -1;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100px;
      width: 100%;
      top: -100px;
      left: 0;
      background-color: $white; } }


  &__get-gift-btn {
    margin-top: 2px;
    padding: 4px 12px;
    box-shadow: 0 4px 15px rgba(177, 149, 239, 0.7);
    @include media("<desktop") {
      padding: 4px 6px; }
    @include media("<tablet") {
      @include mobilemaw(400px);
      display: block;
      padding: 10px;
      margin-top: 15px;
      order: 2;
      font-size: 1.8rem;
      text-align: center;
      box-shadow: 0 0 0 0; } }


  &__list {
    @include resetlist;
    display: flex;
    margin: 3px 34px 0;
    @include media("<huge") {
      flex-grow: 1;
      justify-content: space-between;
      margin: 3px 10px 0 120px; }
    @include media("<large") {
      margin-left: 50px; }
    @include media("<desktop") {
      margin-left: 25px; }
    @include media("<tablet") {
      @include mobilemaw(400px);
      margin-top: 20px;
      flex-flow: column nowrap;
      justify-content: flex-start;
      order: 1;
      padding: 10px; } }


  &__list-item {
    position: relative; }
  &__list-item + &__list-item {
    margin-left: 23px;
    @include media("<desktop") {
      margin-left: 15px; }
    @include media("<tablet") {
      margin-left: 0; } }

  &__list-item--submenu {
    &::before {
      opacity: 0; }
    &--active {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        background-color: $white;
        height: calc(100% + 20px);
        width: calc(100% + 26px);
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: opacity $transition; }
      .main-menu__submenu {
        opacity: 1;
        pointer-events: all; } }
    @include media("<tablet") {
      &::before {
        display: none; } } }

  &__link {
    position: relative;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
    color: $text;
    transition: color $transition;
    @include media("<tablet") {
      display: inline-block;
      font-size: 1.6rem;
      padding: 7px 0; } }

  &__link--tel {
    margin-top: -2px;
    display: flex;
    flex-flow: column;
    text-align: center;
    &:after {
      display: none; }
    &:hover {
      .main-menu__link-text {
        color: $black; }
      .main-menu__link-text--sm {
        opacity: 1; } }
    @include media("<huge") {
      position: absolute;
      top: -60px;
      right: 310px; }
    @include media("<tablet") {
      @include mobilemaw(400px);
      position: static;
      margin: 10px auto 18px;
      order: 3; } }


  &__link, &__submenu-link {
    white-space: nowrap;
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      bottom: -2px;
      width: 100%;
      transform: scaleX(0);
      background-color: lighten($text, 30%);
      transition: transform $transition;
      transform-origin: right; }
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left; } }
    &--no-decor {
      &::after {
        display: none; } }
    &--active {
      &::after {
        transform: scaleX(1); } }
    @include media("<tablet") {
      &:after {
        display: none; }
      &--no-decor {
        display: none;
        &::after {
          display: none; } } } }


  &__link-text {
    font-size: 1.8rem;
    font-weight: 400;
    transition: color $transition;
    @include media("<tablet") {
      font-size: 22px;
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 5px; }
    &--sm {
      font-size: 1.4rem;
      opacity: 0.5;
      line-height: 80%;
      transition: opacity $transition;
      @include media("<tablet") {
        font-size: 1.6rem;
        opacity: 0.7; } } }

  &__submenu {
    @include resetlist;
    position: absolute;
    top: calc(100% + 5px);
    left: -13px;
    display: flex;
    padding: 10px 15px;
    background-color: $white;
    transition: opacity $transition;
    pointer-events: none;
    opacity: 0;
    @include media("<tablet") {
      display: block;
      position: static;
      opacity: 1;
      pointer-events: all;
      padding: 0; } }


  &__submenu-item {
    position: relative;
    @include media("<tablet") {
      padding: 7px 0; } }
  &__submenu-item + &__submenu-item {
    margin-left: 20px;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      display: block;
      height: calc(100% - 7px);
      width: 1px;
      background-color: $text;
      transform: translate(0, -50%); }
    @include media("<tablet") {
      margin: 0;
      padding: 7px 0;
      &::before {
        display: none; } } }

  &__submenu-link {
    position: relative;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    text-decoration: none;
    color: $text;
    &::after {
      bottom: 0; }
    @include media("<tablet") {
      font-size: 1.6rem;
      font-weight: 500;
      text-transform: capitalize;
      letter-spacing: 0; } }

  &__order-call-btn {
    margin-left: 31px;
    padding: 7px 14px;
    @include media("<huge") {
      position: absolute;
      top: -60px;
      right: 110px; }
    @include media("<tablet") {
      position: absolute;
      top: unset;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: 0;
      order: 5;
      font-size: 1.8rem;
      text-align: center; } }

  &__basket {
    position: relative;
    margin-top: 28px;
    margin-left: 50px;
    text-decoration: none;
    @include media("<huge") {
      order: 2;
      margin-left: auto; }
    @include media("<tablet") {
      margin: 0; } }

  &__basket-items {
    position: absolute;
    top: -8px;
    right: -8px;
    display: block;
    width: 23px;
    height: 23px;
    padding: 6px 2px;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    color: $text;
    background-color: $white;
    border-radius: 50%;
    opacity: 0;
    &--active {
      opacity: 1;
      transition: opacity $transition;
      box-shadow: 0 4px 15px rgba(177, 149, 239, 0.7); } }

  #nav-icon {
    display: none;
    @include media("<tablet") {
      display: block; } } }

.fixed-menu-transparent-bg {
  background: transparent;
  &:after {
    opacity: 0; } }

.main-menu--white-text {
  .main-menu__logo {
    color: $white; }
  .main-menu__basket {
    svg {
      fill: $white; } }
  #nav-icon {
    span {
      background-color: $white;
      &:nth-child(2) {
        div {
          background-color: $white;
          &:nth-child(2) {
            background-color: $white; } } } } }
  #nav-icon.open {
    span {
      background-color: $black;
      &:nth-child(2) {
        div {
          background-color: $black;
          &:nth-child(2) {
            background-color: $black; } } } }
    ~ .main-menu__logo {
      color: $black; }
    ~ .main-menu__basket {
      svg {
        fill: $black; } } }
  @include media(">tablet") {
    .main-menu__link {
      color: $white; }
    .main-menu__list-item--submenu {
      &:hover {
        .main-menu__link {
          color: $text; } } } } }

