.constructor {

  &__tabs {
    position: relative;
    z-index: 2;
    display: flex;
    @include media("<desktop") {
      padding-left: 0; }
    @include media("<tablet") {
      height: 40px; } }


  &__tab {
    flex-grow: 1;
    @include resetinteractive();
    @include gbackground(100%);
    padding: 15px 24px 18px 32px;
    font-family: "Montserrat", sans-serif;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    border: 1px solid;
    border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    border-image-slice: 1;
    transition: box-shadow $transition, color $transition;
    cursor: pointer;
    &:active, &:focus {
      outline: none;
 }      //box-shadow: 0 0 3px 0 rgba($black ,0.5)
    &--disabled {
      opacity: 0.5;
      pointer-events: none; }
    &--active {
      padding: 16px 25px 19px 33px;
      background-clip: border-box;
      -webkit-background-clip: border-box;
      color: $white;
      border: 0;
      // safari hacks
      .constructor__tab-sub-text, .constructor__tab-main-text {
        color: $white; } }
    @include media("<large") {
      flex-grow: 1;
      padding: 12px 15px; }
    @include media("<tablet") {
      position: absolute;
      padding: 12px 13px;
      left: 0;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      &--active {
        opacity: 1; } } }

  &__tab + &__tab {
    position: relative;
    margin-left: 53px;
    &:before {
      content: "";
      position: absolute;
      left: -33px;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      width: 0;
      height: 0;
      margin-right: 20px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 13px solid $gradient-end; }
    @include media("<large") {
      margin-left: 30px;
      &:before {
        left: -21px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid $gradient-end; } }
    @include media("<tablet") {
      position: absolute;
      margin-left: 0;
      pointer-events: none;
      &:before {
        display: none; }
      &--active {
        pointer-events: none; } } }


  &__tab-text {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: uppercase;
    max-width: 150px;
    &--tab-1 {
      max-width: 187px; }
    &--tab-2 {
      max-width: 250px; }
    @include media('<tablet') {
      max-width: unset;
      flex-flow: row nowrap; } }

  &__tab-sub-text, &__tab-main-text {
    display: inline;
    text-align: left;
    font-weight: 400;
    // safari hacks
    @include gbackground(100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text; }


  &__tab-sub-text {
    font-size: 1.8rem;
    line-height: 2.0rem;
    letter-spacing: -0.2px;
    text-align: left;
    @include media("<large") {
      font-size: 1.6rem; }
    @include media('<tablet') {
      font-size: 1.2rem;
      font-weight: 800;
      letter-spacing: 2px;
      line-height: 100%; } }

  &__tab-main-text {
    margin-left: -3px;
    font-weight: 900;
    font-size: 3rem;
    line-height: 106%;
    letter-spacing: 2.8px;
    @include media("<large") {
      margin-left: unset;
      font-size: 2rem; }
    @include media('<tablet') {
      margin-left: 10px;
      font-size: 1.2rem;
      font-weight: 800;
      letter-spacing: 2px;
      line-height: 100%; } }

  &__tab-text-mobile {
    display: none;
    @include media('<tablet') {
      margin-left: auto;
      display: block;
      font-size: 1.2rem;
      font-weight: 800;
      letter-spacing: 4px;
      line-height: 100%;
      &--thin {
        font-weight: 500; } } }

  &__tab-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
    font-size: 1.4rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    border: 1px solid;
    border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    border-image-slice: 1;
    color: $text;
    transition: height $transition*5, opacity $transition;
    pointer-events: all;
    @include media("<tablet") {
      top: 150%;
      border: none; } }

  &__tab-submenu-item {
    position: relative;
    opacity: 0.5;
    letter-spacing: 1px;
    transition: opacity $transition;
    &:hover {
      opacity: 0.7; } }


  &__tab-submenu-item + &__tab-submenu-item {
    position: relative;
    margin-left: 15px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
      display: block;
      height: 10px;
      width: 1px;
      background-color: $text; } }

  &__tab-submenu-item--active {
    opacity: 1; } }

.constructor__tab--submenu {
  .constructor__tab-submenu {
    height: unset;
    opacity: 1;
    padding: 10px; } }

