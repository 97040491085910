// COLORS
$black: #000000;
$white: #FFFFFF;
$text: #444444;
$btn: #82AD24;
$main-link: #D62267;
$main-link-lighter: #F16299;

$gradient-start: #FF8BDE;
$gradient-end: #669FFF;


$bg-color: #F5F8FF;
// END COLORS

$transition: 300ms;
