.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  padding: 80px 10px 10px;
  background-color: $bg-color;
  transition: opacity $transition;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  * {
    pointer-events: none; }
  @include media("<huge") {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 10px 10px; }

  &--active {
    opacity: 1;
    pointer-events: all;
    z-index: 15;
    * {
      pointer-events: all; } }

  &__close {
    @include resetinteractive();
    position: absolute;
    top: 50px;
    right: 70px;
    cursor: pointer;
    @include media ("<tablet") {
      top: 32px;
      right: 30px; }
    &:focus {
      outline: 0;
      svg {
        stroke: url(#linear-grad-2); } }
    &:active {
      svg {
        transform: translateY(-2px); } }
    @include media("<tablet") {
      top: 20px;
      right: 10px;
      width: 30px; } }

  &__name {
    @include resetmargin;
    position: absolute;
    top: 90px;
    left: 100px;
    @include subtitle(3.6rem);
    max-width: 60%;
    word-spacing: 500px;
    @include media("<huge") {
      position: static;
      margin-bottom: 15px;
      max-width: 100%;
      text-align: center;
      font-size: 3rem;
      word-spacing: unset; }
    @include media("<desktop") {
      font-size: 2rem; } }

  &__thumbs {
    position: absolute;
    top: 70px;
    left: 50px; }

  &__thumb-wrap {
    @include resetmargin();
    height: 121px;
    width: 121px;
    cursor: pointer;
    transform: scale(1);
    transition: transform $transition;
    &:hover {
      transform: scale(1.1); } }

  &__thumb-wrap + &__thumb-wrap {
    margin-top: 53px; }
  &__thumb-img {
    width: 100%;
    object-fit: contain; }

  &__image {
    width: 100%;
    max-width: 600px;
    @include media("<huge") {
      margin-top: 10px;
      max-width: 500px;
      width: 100%; } } }




.product-modal {
  background-image: url('../img/splash-bg-2.jpg');
  background-size: cover;
  @include media("retina2x") {
    background-image: url('../img/splash-bg-2@2x.jpg');
    max-width: 1920px; } }

.document-modal {
  background-image: url('../img/splash-bg-1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .modal__name {
    word-spacing: 5000px;
    @include media("<huge") {
      word-spacing: initial; } }

  @include media("retina2x") {
    background-image: url('../img/splash-bg-1.jpg');
    max-width: 1920px; }

  .modal__image {
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    object-fit: contain;
    @include media("<huge") {
      margin-top: 10px;
      max-width: 500px;
      max-height: unset;
      width: 100%; } } }
