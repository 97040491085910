.similar-items {
  margin-bottom: 50px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include media("<large") {
      > div:nth-child(n+5) {
        display: none; } }
    @include media("<desktop") {
      > div:nth-child(n+4) {
        display: none; } }
    @include media("<tablet") {
      justify-content: space-around;
      > div:nth-child(n+3) {
        display: none; } } }


  &__item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 230px; }
  &__main-title {
    @include subtitle(3.6rem);
    margin-bottom: 50px;
    @include media("<desktop") {
      font-size: 3rem; }
    @include media("<tablet") {
      font-size: 2.2rem; } }
  &__title {
    @include subtitle(2.4rem);
    @include resetmargin;
    @include media("<tablet") {
      font-size: 1.4rem; } }

  &__img-wrap {
    @include resetmargin();
    margin-top: auto;
    img {
      max-width: 100%; } }
  &__link {
    margin-top: auto;
    text-decoration: none;
    text-align: center;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block; } }


  &__price {
    @include subtitle(1.8rem);
    @include price-after(); } }
