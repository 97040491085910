.slider {
  .swiper-slide {
    position: relative; }

  &__animation-1-img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 450px;
    transform: translate(-50%, -50%);
    &:nth-child(1) {
      z-index: 3; }
    &:nth-child(2) {
      z-index: 2; }
    @include media("<xl") {
      max-width: 340px;
      left: 60%; }
    @include media("<tablet") {
      max-width: 240px;
      width: 225px;
      top: 55%; }
    img {
      width: 100%; } }

  &__animation-2-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 450px;
    min-height: 675px;
    transform: translate(-50%, -50%);
    @include media("<xl") {
      width: 350px;
      left: 60%; }
    @include media("<tablet") {
      width: 225px;
      min-height: unset;
      top: 55%; }
    img {
      width: 100%; } }

  &__animation-swirl {
    transform: translate(-50%, -50%) scale(0.01) rotate(180deg);
    max-width: unset;
    opacity: 0;
    width: 100%;
    &--animate {
      animation-delay: 0s;
      animation-duration: 1s;
      animation-name: swirl;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; } }

  &__animation-drop {
    transform: translate(-50%, -50%) scale(2) rotate(-180deg);
    opacity: 0;
    width: 100%;
    &--animate {
      animation-delay: 0s;
      animation-duration: 1s;
      animation-name: drop;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; } }

  &__animation-slide {
    &-top {
      z-index: 3;
      position: relative;
      transform: translate(-50%, -1700px);
      display: flex;
      justify-content: center;
      align-items: center;
      &--animate {
        animation-delay: 0s;
        animation-duration: 1s;
        animation-name: slide-top;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; }
      img {
        width: 175px;
        @include media("<xl") {
          width: 134px; }
        @include media("<tablet") {
          width: 87px; } } }
    &-bottom {
      transform: translate(-50%, 1700px);
      display: flex;
      justify-content: center;
      align-items: center;
      &--animate {
        animation-delay: 0s;
        animation-duration: 1s;
        animation-name: slide-bottom;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; }
      &--layer-1 {
        z-index: 2; }
      &--layer-2 {
        z-index: 4; }
      img {
        width: 100%;
        position: absolute; } } } }


@keyframes swirl {
  from {
    transform: translate(-50%, -50%) scale(0.01) rotate(180deg);
    opacity: 0; }
  to {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    opacity: 1; } }

@keyframes drop {
  from {
    transform: translate(-50%, -50%) scale(2) rotate(-180deg);
    opacity: 0; }
  to {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
    opacity: 1; } }

@keyframes slide-top {
  from {
    transform: translate(-50%, -1700px); }
  to {
    transform: translate(-50%, -50%); } }

@keyframes slide-bottom {
  from {
    transform: translate(-50%, 1700px); }
  to {
    transform: translate(-50%, -50%); } }

@keyframes letterShow {
  from {
    opacity: 0;
    transform: translate(0, -10px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes letterHide {
  from {
    opacity: 1;
    transform: translate(0, 0px); }
  to {
    opacity: 0;
    transform: translate(0, 10px); } }
