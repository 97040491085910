.faq {
  @include container--content;
  font-size: 1.8rem;
  @include media ("<tablet") {
    font-size: 1.4rem; }

  &__title-wrapper-1 {
    @include gbackground(100%);
    margin-bottom: 100px; }

  &__title-wrapper-2 {
    margin-left: 15px; }

  &__title {
    box-shadow: 125px 0 0 0 $bg-color, 210px 0 0 0 $bg-color, 340px 0 0 0 $bg-color, 525px 0 0 0 $bg-color, 710px 0 0 0 $bg-color;
    display: inline;
    font-size: 7.2rem;
    font-weight: 800;
    color: $white;
    padding-right: 15px;
    text-transform: uppercase;
    @include media ("<tablet") {
      font-size: 3.0rem; } }

  .accordion {
    &__title {
      @include subtitle(1.8rem);
      font-weight: 800;
      max-width: 900px; }

    &__btn {
      font-size: 1.6rem;
      font-weight: normal;
      width: 120px;
      flex-shrink: 0;
      align-self: flex-end;
      max-height: 48px;
      @include media ("<tablet") {
        width: 25px; } }

    &__content {
      max-width: 726px;
      line-height: 3.0rem; } } }
