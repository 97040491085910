.tabs {

  &__buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    &::after {
      @include gbackground(100%, 0%);
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      height: 1px;
      width: 100%; }

    @include media("<tablet") {
      display: none; } }


  &__tab-button, &__tab-button-mob {
    @include gbackground(200%, 50%);
    padding: 10px 55px;
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: transparent;
    border: none;
    background-position: 100% 0;
    background-clip: text;
    -webkit-background-clip: text;
    cursor: pointer;
    transition: background-position $transition;
    &:hover {
      background-position: 0 0; }
    &:active, &:focus {
      outline: none;
      box-shadow: 0 0 3px 0 rgba($black ,0.5); }

    // fix for safari background-clip: text, so lame
    span {
      @include gbackground(200%, 50%);
      font-size: 1.8rem;
      font-weight: 800;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: transparent;
      border: none;
      background-position: 100% 0;
      background-clip: text;
      -webkit-background-clip: text;
      cursor: pointer;
      transition: background-position $transition;
      &:hover {
        background-position: 0 0; } }

    &--active {
      background-clip: border-box;
      -webkit-background-clip: border-box;
      color: $white;
      > span {
        color: $white; } } }


  &__text-area {} // because of landing-1, should this become "&__content-area"?

  &__tab-button-mob {
    display: none;
    @include media("<tablet") {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px 10px;
      border-bottom: 2px solid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
      border-image-slice: 1;
      &:hover {
        background-position: 100% 0; }
      span {
        margin-left: auto;
        &:hover {
          background-position: 100% 0; } }
      svg {
        fill: url(#linear-grad-2);
        margin-left: auto;
        transition: transform $transition; } }

    &--active {
      svg {
        fill: $white;
        transform: scaleY(-1); } } }


  &__text-area {
    position: relative;
    overflow: hidden; }

  &__text-wrap { // because of landing-1, should this become "&__content-wrap"?
    position: absolute;
    top: 0;
    padding: 40px;
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    transition: opacity $transition;
    p {
      font-size: 1.8rem;
      line-height: 3.0rem; }
    &--active {
      position: static;
      opacity: 1;
      pointer-events: all;
      max-height: unset; }




    @include media("<tablet") {
      position: static;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity $transition, max-height $transition;
      p {
        font-size: 1.4rem;
        line-height: 2.0rem; }

      &--active {
        padding: 20px;
        max-height: 2500px;
        border-bottom: 2px solid;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
        border-image-slice: 1;
        transition: opacity $transition, max-height $transition*5; }

      img {
        max-width: 100%; }

      iframe {
        width: 100%; } } } }


