.landing-2 {

  section:not(.landing-2__flat-form-section) {
    @include container--content; }

  section + section {
    &:not(.landing-2__flat-form-section) {
      margin-top: 150px;
      @include media ("<tablet") {
        margin-top: 100px; } } }

  &__header {
    display: flex;
    @include media ("<desktop") {
      flex-flow: column nowrap; } }

  &__main-title-wrapper {
    min-width: 60%; }

  // 1ST SECTION
  &__main-title {
    color: $white;
    font-size: 4.8rem;
    line-height: 6rem;
    font-weight: 900;
    text-transform: uppercase;
    @include media ("<large") {
      font-size: 3.5rem; // 3.6rem
      line-height: 4.5rem; }
    @include media ("<desktop") {
      font-size: 3.1rem;
      line-height: 4.1rem; }
    @media screen and (max-width: 500px) {
      font-size: 2.5rem;
      line-height: 3.2rem;
      margin-top: 0; }
    @include media ("<=tiny") {
      font-size: 2rem;
      line-height: 2.7rem; }

    span {
      padding: 10px 15px;
      @include gbackground (700px);
      @include media ("<desktop") {
        @include gbackground (500px); }
      @include media ("<tablet") {
        padding: 5px; }
      @include media ("<=tiny") {
        @include gbackground (300px); }
      &::after {
        white-space: break-spaces;
        content: "\A"; } } }

  &__after-title-text {
    font-size: 1.8rem;
    line-height: 3rem;
    color: $text;
    text-transform: uppercase;
    margin-top: 30px;
    span {
      font-weight: 800; }
    @include media ("<tablet") {
      font-size: 1.6rem;
      margin-top: 22px; }
    @include media ("<=tiny") {
      font-size: 1rem;
      line-height: 1.4rem;
      color: $white; } }

  &__header-info-tags {
    display: flex;
    justify-content: space-between;
    max-width: 575px;
    color: $text;
    margin-top: 90px;
    @include media ("<desktop") {
      margin-top: 20px;
      max-width: 100%; }

    > div {
      flex-basis: 49%; }


    .landing-2__text-highlight {
      font-weight: 800;
      font-size: 3.6rem;
      line-height: 2rem;
      padding: 10px;
      @include media ("<tablet") {
        font-size: 2.5rem; }
      @include media ("<=tiny") {
        font-size: 2rem;
        padding: 5px; } } }

  &__header-tag-text--1 {
    font-size: 1.8rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    max-width: 190px;
    @include media ("<tablet") {
      font-size: 1.6rem; }
    @include media ("<=tiny") {
      font-size: 1.2rem;
      color: $white;
      max-width: 150px; } }

  &__header-tag-text--2 {
    font-size: 1.8rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    max-width: 126px;
    @include media ("<tablet") {
      font-size: 1.6rem; }
    @include media ("<=tiny") {
      font-size: 1.2rem;
      color: $white; } }

  &__text-highlight {
    @include gbackground (100%);
    display: inline-block;
    color: $white;
    padding: 5px 15px;
    // padding: 3px 7px // from landing-1
    font-weight: 900; }

  &__header-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3rem;
    margin-top: 165px;
    padding: 24px;
    max-width: 396px;
    @include media ("<desktop") {
      margin: 40px auto 0; }
    @include media ("<tablet") {
      font-size: 1.6rem;
      margin-top: 25px; }
    @include media ("<tiny") {
      width: 100%;
      font-size: 1.2rem;
      padding: 15px; } }

  &__subtitle {
    @include subtitle(3.6rem);
    line-height: 4.4rem;
    font-weight: 800;
    letter-spacing: 0.2rem;
    @include media ("<large") {
      font-size: 3rem; }
    @include media ("<tablet") {
      font-size: 2.2rem;
      line-height: 3rem; } }


  // 2ND SECTION
  &__orb-list-1 {
    .orb-list__wrap {
      flex-wrap: wrap;
      margin-top: 80px;
      @include media ("<large") {
        justify-content: center; }
      @include media ("<tablet") {
        margin-top: 50px; }

      .orb-list__item {
        width: 196px;
        @include media (">=large") {
          align-items: baseline; }
        @include media ("<large") {
          flex-basis: 33%;
          span {
            text-align: center; } }
        @include media ("<tablet") {
          flex-basis: 50%; }
        @include media ("<=tiny") {
          max-width: 150px;
          span {
            margin-top: -20px;
            @include media ("<huge") {
              font-size: 1.2rem;
              margin: 0 5px; } }
          .orb-list__svgs {
            width: 95px; }
          .orb-list__svg-bg {
            width: 100%; }
          .orb-list__svg-img {
            width: 45px; } }

        &:nth-child(2), &:nth-child(3) {
          span {
            @include media ("<=tiny") {
              margin-top: -15px; } } }

        &:nth-child(3) {
          @include media ("<=tiny") {
            margin-right: 30px; } }

        &:nth-child(4), &:nth-child(5) {
          @include media ("<=tiny") {
            max-width: 110px;
            span {
                margin-top: -22px; } } } } } }

  &__orb-list-1-btn {
    padding: 18px 24px;
    font-size: 1.8rem;
    line-height: 1.3rem;
    max-width: 396px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 45px auto;
    @include media ("<=tiny") { // or tablet?
      display: none; } }


  // 3RD SECTION
  &__promo-section {
    position: relative;
    z-index: 3;

    // REVIEW
    .landing-2__subtitle {
      .landing-2__text-highlight {
        @include media ("<tablet") {
          padding: 5px 10px; }
        @include media ("<=tiny") {
 } } }          // width: min-content
 }    // /REVIEW

  &__promo {
    position: relative;
    margin-bottom: 135px;
    @include media ("<tablet") {
      margin: 0; }

    > div { // promo-rows
      display: flex;
      @include media ("<tablet") {
        display: block; } } }

  &__promo-row--1 {
    justify-content: space-between;
    position: relative;
    top: 35px;
    @include media ("<desktop") {
      position: static;
      margin-top: 70px;
      justify-content: space-around; } }

  &__promo-row--2 {
    justify-content: space-around;
    position: relative;
    margin-left: 163px;
    margin-top: 30px;
    @include media ("<desktop") {
      position: static;
      margin-top: 65px;
      margin-left: 0; }
    @include media ("<tablet") {
      margin-top: 40px;
      left: 0; } }



  &__promo {

    .svg-grad-big-arrow {
      left: 110px; }

    .landing-2__promo-item {
      position: relative;
      display: flex;
      flex-basis: 50%;
      @include media ("<desktop") {
        flex-basis: auto; }
      @include media ("<tablet") {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 70px; }
      @include media ("<=tiny") {
        display: block;
        margin-bottom: 60px;
        position: static; }
      > span {
        color: $white;
        font-size: 28.8rem;
        font-weight: 900;
        line-height: 35.1rem;
        @include media ("<desktop") {
          display: none; } }
      > .landing-2__promo-item-description {
        display: flex;
        flex-flow: column;
        @include media ("<=tiny") {
          // display: block
          text-align: center;
          margin: 20px auto 0; }
        > span {
          &:first-of-type {
            color: $text;
            font-size: 1.8rem;
            font-weight: 800;
            text-transform: uppercase;
            @include media ("<desktop") {
              font-size: 1.4rem; } }
          &:not(first-of-type) {
            font-size: 1.6rem; } } }

      .svg-promo {
        margin-bottom: 15px;
        @include media ("<tiny") {
          margin: 0 auto 20px; } }

      .landing-2__text-highlight {
        padding: 3px 5px; } } }

  &__promo-item--1 {
    .landing-2__promo-item-description {
      max-width: 448px;
      margin-right: 10px;
      span:first-of-type {
        margin-bottom: 20px; } } }

  &__promo-item--2 {
    bottom: 25px;
    @include media ("<desktop") {
      bottom: auto; }
    .landing-2__promo-item-description {
      align-self: center;
      max-width: 325px;
      span:first-of-type {
        margin-bottom: 25px; } } }

  &__promo-item--3 {
    .landing-2__promo-item-description {
      align-self: center;
      max-width: 230px; } }

  &__promo-item--4 {
    margin-left: 100px;
    @include media ("<tiny") {
      margin-left: 0; }
    .landing-2__promo-item-description {
      align-self: center;
      max-width: 245px; } }


  // 4TH SECTION
  &__calculator-section {
    // background-image: url("../img/landing-2_calculator-bg.png")
    // background-repeat: no-repeat
    // background-position: left center
    // overflow: visible
    position: relative;
    z-index: 2;



    h3 { // REVIEW
      font-size: 3.6rem;
      line-height: 2rem;
      font-weight: 800;
      padding: 20px;
      text-transform: uppercase; } }



  // 5TH SECTION
  &__orb-list-2 {
    position: relative;
    z-index: 3;


    .landing-2__subtitle {
      margin-bottom: 100px;
      @include media ("<desktop") {
        margin-bottom: 50px; }
      @include media ("<tablet") {
        margin-bottom: 30px; } }

    .orb-list__item {
      width: 303px;
      flex-basis: 33%;
      @include media ("<tablet") {
        flex-basis: 50%;
        max-width: 185px; }
      @include media ("<=tiny") {
        max-width: 144px; }
      span {
        max-width: 296px;
        @include media ("<tablet") {
          text-align: center; }
        @include media ("<=tiny") {
          line-height: 2rem;
          margin-top: -10px; } } }

    .orb-list__wrap {
      flex-wrap: wrap;
      > div:nth-of-type(1) {
        .orb-list__svg-img {
          margin-top: 0; }
        span {
          max-width: 200px;
          margin-top: 15px; } }
      > div:nth-of-type(3) {
        .orb-list__svgs {
          @include media ("<=tiny") {
            width: 110px; } }
        .orb-list__svg-bg {
          @include media ("<=tiny") {
            width: 110px; } } }
      > div:nth-of-type(5) {
        span {
          max-width: 276px; } }
      > div:nth-of-type(6) {
        .orb-list__svgs {
          @include media ("<=tiny") {
            width: 95px; } }
        .orb-list__svg-bg {
          @include media ("<=tiny") {
            width: 95px; } }
        span {
          // max-width: 303px
          margin-top: 15px; } } }

    .orb-list__svgs {
      @include media ("<=tiny") {
        width: 105px; } }

    .orb-list__svg-bg {
      @include media ("<=tiny") {
        width: 105px; } }

    .orb-list__svg-img {
      @include media ("<=tiny") {
        width: 60px; } } }


  // 6TH SECTION
  &__flat-form-section {
    @include container--content;
    position: relative;
    z-index: 2;
    margin-top: 70px;

    &::before {
      content: " ";
      display: block;
      overflow: visible;
      position: absolute;
      width: 100%;
      z-index: -1;
      top: 50%;
      transform: translate(0, -50%);
      right: calc((1440px - 100vw)/2);
      @include media ("<huge") {
        right: calc((1280px - 100vw)/2); }
      @include media ("<large") {
        right: calc((1024px - 100vw)/2); }
      @include media ("<desktop") {
        right: calc((768px - 100vw)/2); }
      @include media (">=tablet") {
        background-image: url("../img/flat-form-desktop-bg.jpg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: 100%;
        width: 998px;
        height: 1000px; }
      @include media ("<tablet") {
        background-image: url("../img/landing-mobile-form-bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        left: 0;
        width: 100%;
        height: 965px; }
      @include media ("retina2x") {
        background-image: url("../img/flat-form-desktop-bg@2x.jpg");
        background-size: cover; } }

    > svg {
      stroke: url(#linear-grad-4);
      fill: transparent;
      position: absolute;
      left: 53px;
      top: -78px;
      pointer-events: none;
      @include media ("<huge") {
        display: none; } }

    .form__btn {
      margin-left: 19px;
      font-size: 1.4rem;
      line-height: 1.3rem;
      padding: 15px 20px;
      z-index: 1;
      @include media ("<tablet") {
        margin-left: 0;
        width: 100%; } }

    .form__cbx-label {
      margin-top: 19px;
      span {
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: 0.2rem;
        color: $text;
        z-index: 1; }
      @include media ("<tablet") {
        display: none; } } }

  &__flat-form {
    margin-top: 57px;
    .form__btn {
      height: 56px; } }

  &__flat-form-input--wrap {
    display: flex;
    max-height: 56px;
    @include media ("<large") {
      flex-basis: 50%;
      max-height: unset; }
    @include media ("<tablet") {
      display: block;
      max-width: 500px;
      max-height: unset;
      margin: 0 auto; }


    .wpcf7-not-valid {
      outline: 1px solid $main-link;
      color: $main-link;
      &::placeholder {
        color: $main-link; } }

    p {
      margin: 0; } }

  &__flat-form-input-clientinfo {
    @include gbackground (100%);
    opacity: 0.7;
    display: flex;
    @include media ("<large") {
      flex-wrap: wrap; }
    @include media ("<tablet") {
      display: block;
      margin-bottom: 20px;
      opacity: 1;
      background: none; }

    p {
      margin: 0; } }

  &__flat-form-input {
    background-color: transparent;
    // width: 235px
    width: 100%;
    padding: 15px;
    border: none;
    color: $white;
    font-weight: 800;
    font-size: 1.8rem;
    @include media ("<tablet") {
      background: $white;
      margin-bottom: 30px;
      color: $text; }
    //   width: 100%
    //   opacity: 0.7
    // @include media ("<tablet")
    //   background: $white
    //   opacity: 1
    //   color: $black
    //   font-size: 1.4rem
    &::placeholder {
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.3rem;
      @include media ("<tablet") {
        color: $text;
        opacity: 0.5; } }

    &:focus {
      opacity: 0.9;
      @include media ("<tablet") {
        opacity: 1; } } }

  &__flat-form-input--wrap + &__flat-form-input--wrap {
    &::before {
      content: " ";
      background-color: $white;
      width: 1px;
      height: 28px;
      align-self: center; } }

  &__flat-form-input--wrap {
    &:nth-child(3) {
      &::before {
        @include media ("<desktop") {
          display: none; } } } }



  // 7TH SECTION
  &__partners-section {
    position: relative;
    z-index: 3;

    .partners-swiper-container {
      overflow: hidden;
      position: relative;
      margin: 100px 0;
      margin-left: -100px;
      padding-left: 100px;
      @include media ("<desktop") {
        margin: 50px 0;
        padding-bottom: 90px;
        padding-left: unset; }
      @include media ("<=tiny") {
        padding-bottom: 50px; }

      .swiper-wrapper {
        max-height: 100%;
        height: unset; } }

    .swiper-slide {
      display: flex; }

    .landing-2__swiper-img--wrapper {
      display: block;
      width: 272px;
      flex-shrink: 0;
      margin-right: 55px;
      @include media ("<desktop") {
        margin-right: 30px; }
      @include media ("<tablet") {
        position: static;
        width: 100px;
        margin: 0 20px 0 0; }
      @include media ("<=tiny") {
        width: 58px; } }

    .landing-2__swiper-img {
      width: 100%; }

    .landing-2__swiper-text {
      display: block;
      text-transform: uppercase;
      @include media ("<tablet") {
        max-width: 500px;
        margin: 0; }

      h3 {
        color: $text;
        font-size: 2.4rem;
        margin: 0;
        font-weight: 800;
        @include media ("<huge") {
          font-size: 2rem; }
        @include media ("<tablet") {
          font-size: 1.8rem; }
        @include media ("<=tiny") {
          font-size: 1.6rem;
          line-height: 2rem; } }

      span {
        color: $text;
        font-size: 1.8rem;
        @include media ("<huge") {
          font-size: 1.5rem; }
        @include media ("<tablet") {
          font-size: 1.4rem; }
        @include media ("<=tiny") {
          font-size: 1.2rem;
          line-height: 1.5rem; } }

      p {
        padding: 50px 140px 0 0px;
        margin: 0;
        font-size: 1.8rem;
        line-height: 3.0rem;
        text-transform: none;
        text-align: justify;
        @include media ("<huge") {
          font-size: 1.5rem;
          line-height: 2.8rem; }
        @include media ("<desktop") {
          padding: 30px 25px 0 0; }
        @include media ("<tablet") {
          padding: 0;
          text-align: left;
          font-size: 1.4rem;
          margin-top: 80px;
          margin-left: -120px;
 }          // max-width: 500px
        @include media ("<=tiny") {
          margin-top: 40px;
          margin-left: -78px;
          line-height: 2rem; } } } }

  .swiper-button-next, .swiper-button-prev {
    border: 1px solid;
    border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    border-image-slice: 1;
    width: 33px;
    height: 86px;
    margin-top: -43px;
    background: $bg-color;
    z-index: 1;
    @include media ("<desktop") {
      display: none; }
    &:hover {
      background: #e5edff;
      transition: 400ms; }
    svg {
      fill: url(#linear-grad-4); }
    &::after {
      display: none; } }

  .swiper-button-next {
    transform: rotate(180deg);
    right: 1px; }

  .swiper-button-prev {
    left: 25px; }

  .swiper-pagination { // REVIEW
    @include media (">=desktop") {
      display: none; }
    width: 105%;
    bottom: auto; // 15px
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    margin-top: 70px; // 85px
    @include media ("<tablet") {
      margin-top: 40px; } // 55px
    @include media ("<=tiny") {
      margin-top: 30px; } // 45px

    .swiper-pagination-bullet {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
      background-color: $text;
      border-radius: unset;
      &:focus {
        outline: none; }
      &::after {
        content: " ";
        padding: 15px;
        display: block;
        position: relative;
        bottom: 13px; }
      &-active {
        @include gbackground(100%); } } }

  // 8TH SECTION
  &__form-section {
    position: relative;
    z-index: 1;
    padding-bottom: 30px !important;
    overflow: hidden;
    &:focus, &:active {
      outline: none; }

    &::before {
      @include media ("<tablet") {
        content: " ";
        display: block;
        overflow: visible;
        position: absolute;
        width: 100%;
        z-index: -1;
        background-image: url("../img/landing-mobile-form-bg.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 750px;
        left: 0;
        height: 1200px; } }

    .catalog-form__wrapper {
      @include media ("<=tiny") {
        margin-top: 0; } } }


  //.catalog-form__btn
  //  @include media ("<tablet")
 }  //    display: none
