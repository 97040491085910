.breadcrumbs {
  @include resetlist();
  display: flex;
  @include media("<tablet") {
    display: none; }

  li:first-child {
    a {
      text-decoration: underline; } }

  &__item + &__item {
    margin-left: 20px;
    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 20px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 9px solid lighten($text, 30%); } }

  &__link {
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: lighten($text, 30%);
    text-decoration: none; } }
