.landing3__slider, .landing3__info, .landing3__delivery, .landing3__orb-list {
  @include container--content(); }

.landing3__header {
  position: relative;
  z-index: 3; }
.landing3__pitch {
  z-index: 2; }
.landing3__bindings {
  position: relative;
  z-index: 2; }
.landing3__delivery {
  position: relative;
  z-index: 2; }
.landing3__slider {
  z-index: 1; }
.landing3__info {
  position: relative;
  z-index: 2; }

.landing3 {
  background-image: url('../img/landing3-bg-3.jpg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 873px 1871px;
  @include media("retina2x", ">tablet") {
    background-image: url('../img/landing3-bg-3@2x.jpg'); }
  @include media("<huge") {
    background-position: bottom -200px right; }
  @include media("<desktop") {
    background-position: bottom -200px right;
    background-size: 436px 935px; }


  &__header {
    padding-top: 133px;
    background-image: url('../img/landing3_header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    &-wrapper {
      @include container--content();
      padding-bottom: 163px; }
    @include media("<huge") {
      padding-top: 163px;
      &-wrapper {
        padding-bottom: 80px; } }
    @include media("<tablet") {
      padding-top: 75px;
      background-image: url('../img/landing3_header-bg@2x.jpg');
      background-position: center;
      &-wrapper {
        padding-bottom: 20px; } } }




  &__header-title {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 0;
    span {
      padding: 3px 15px;
      @include gbackground(550px);
      display: inline-block;
      @include subtitle(4.8rem);
      color: $white;
      font-weight: 900;
      &:nth-child(2) {
        padding-top: 0;
        padding-bottom: 0; }
      @include media("<large") {
        font-size: 3.6rem;
        padding: 2px 10px; }
      @include media("<tablet") {
        font-size: 2rem;
        padding: 2px 7px; } } }

  &__header-text {
    margin-top: 36px;
    max-width: 460px;
    @include subtitle(1.8rem);
    line-height: 30px;
    color: $white;
    font-weight: 600;
    @include media("<large") {
      font-size: 1.6rem; }
    @include media("<tablet") {
      font-size: 1.4rem; } }

  &__header-interactives {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 101px;
    @include media("<huge") {
      justify-content: flex-start;
      margin-top: 0; }
    @include media("<tablet") {
      flex-flow: column-reverse nowrap;
      align-items: center; } }

  &__header-btn {
    margin-top: auto; }

  &__header-svg-btn {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    appearance: none;
    width: 170px;
    height: 191px;
    cursor: pointer;
    &:focus, &:active {
      outline: none; }
    .landing3__header-svg-btn-bg {
      position: absolute;
      top: 0;
      left: 0;
      fill: $white;
      z-index: 1; }
    span {
      @include subtitle(1.8rem);
      position: relative;
      z-index: 2;
      margin: 10px 0 5px;
      @include gbackground(200%, 50%);
      padding: 10px 55px;
      font-size: 1.7rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: transparent;
      border: none;
      background-position: 100% 0;
      background-clip: text;
      -webkit-background-clip: text;
      transition: background-position $transition;
      //&:hover
      //  background-position: 0 0
      //&:active, &:focus
      //  outline: none
 }      //  box-shadow: 0 0 3px 0 rgba($black ,0.5)
    .landing3__header-svg-btn-play {
      position: relative;
      stroke-width: 2px;
      fill: url(#linear-grad-4);
      z-index: 2; }
    @include media("<huge") {
      margin-left: 250px; }
    @include media("<desktop") {
      margin-left: auto; }
    @include media("<tablet") {
      margin-left: 0;
      margin-bottom: 30px; } }


  &__pitch {
    position: relative;
    padding: 300px 0 0 0;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      background-image: url('../img/landing3-repeating-bg.jpg');
      background-repeat: no-repeat; }
    &::before {
      left: -1050px;
      top: 140px;
      width: 1643px;
      height: 1095px;
      transform: rotate(149deg);
      z-index: 1; }
    &::after {
      right: -890px;
      top: 0;
      width: 1824px;
      height: 1217px;
      transform: rotate(-162deg);
      z-index: 1; }
    .landing3__list-wrapper {
      @include container--content();
      position: relative;
      z-index: 3; }
    @include media("retina2x", ">tablet") {
      &::after, &::before {
        background-size: cover;
        background-image: url('../img/landing3-repeating-bg@2x.jpg'); } }
    @include media("<large") {
      &::before {
        background-size: cover;
        left: -530.2px;
        top: 40px;
        width: 717px;
        height: 478px;
        transform: rotate(170deg); }
      &::after {
        display: none; } }
    @include media("<desktop") {
      padding-top: 140px; } }


  &__pitch-list {
    max-width: 570px;
    @include media("<desktop") {
      padding-right: 150px; } }

  &__pitch-img {
    position: absolute;
    z-index: 3;
    &--bottle-1 {
      width: 531px;
      left: calc(100% - 290px);
      top: 96px;
      @include media("<large") {
        width: 300px; }
      @include media("<desktop") {
        width: 150px;
        right: 10px;
        left: unset;
        top: 146px; } }

    &--souvenir-1 {
      width: 175px;
      left: 71px;
      top: -255.4px;
      @include media("<desktop") {
        width: 50px;
        left: 41px;
        top: -115.4px; } }
    &--souvenir-2 {
      width: 186px;
      left: 406px;
      top: -204.4px;
      @include media("<desktop") {
        width: 66px;
        left: 40%;
        top: -84.4px; } }
    &--souvenir-3 {
      width: 322px;
      right: 250px;
      top: -252.4px;
      @include media("<large") {
        width: 150px; }
      @include media("<desktop") {
        width: 108px;
        left: 70%;
        top: -131.4px; } }
    &--souvenir-4 {
      width: 287px;
      right: calc(100% - 190px);
      top: 466px;
      @include media("<large") {
        display: none; } }
    &--souvenir-5 {
      width: 211px;
      left: 304px;
      top: 222px;
      @include media("<large") {
        display: none; } }
    &--souvenir-6 {
      width: 280px;
      right: 430px;
      top: 102px;
      @include media("<large") {
        display: none; } } }

  &__bindings {
    @include container--content();
    @include media("<large") {
      width: 100%; }
    @include media("<desktop") {
      .landing3__text {
        margin-bottom: 20px; } } }


  &__bindings-wrapper {
    display: flex;
    justify-content: space-between;
    @include media("<desktop") {
      flex-flow: column nowrap; } }

  &__binding {
    @include subtitle(1.8rem);
    font-weight: 900;
    letter-spacing: 1px;
    &--def {
      p {
        display: inline-block;
        margin-left: 80px; } }
    @include media("<large") {
      flex-basis: 50%;
      img {
        width: 100%; }
      &--def {
        p {
          display: block;
          margin-left: unset; } } }
    @include media("<desktop") {
      img {
        max-width: 500px; } } }

  &__binding-img--mirror {
    margin-left: -50px;
    @include media("<large") {
      margin-left: unset; } }


  &__delivery {
    position: relative;
    margin: 100px auto 100px;
    @include media("<desktop") {
      margin: 60px auto 100px;
      .landing3__text {
        margin-bottom: 30px; } } }

  &__delivery-img {
    width: 767px;
    position: absolute;
    right: 50px;
    top: 70px;
    @include media("<large") {
      width: 467px; }
    @include media("<desktop") {
      position: static;
      width: 100%;
      max-width: 500px;
      margin: 20px 0; } }


  &__delivery-text {
    margin-bottom: 50px;
    color: $text;
    @include media("<desktop") {
      margin-bottom: 20px; } }



  &__slider {
    position: relative;
    @include container--content();
    padding-bottom: 30px;
    margin-bottom: 109px;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      background-image: url('../img/landing3-repeating-bg.jpg');
      background-repeat: no-repeat; }
    &::before {
      left: -1049px;
      top: -400px;
      width: 1643px;
      height: 1095px;
      transform: rotate(163deg);
      z-index: 1; }
    &::after {
      right: -1180px;
      top: 0;
      width: 1824px;
      height: 1217px;
      transform: rotate(-208deg); }
    @include media("retina2x", ">tablet") {
      &::after, &::before {
        background-size: cover;
        background-image: url('../img/landing3-repeating-bg@2x.jpg'); } }
    @include media("<huge") {
      margin-bottom: 100px; }

    @include media("<tablet") {
      margin-bottom: 50px;
      &:before {
        background-size: cover;
        left: -419px;
        top: -400px;
        width: 705px;
        height: 470px; }
      &:after {
        background-size: cover;
        right: -540px;
        top: 60px;
        width: 921px;
        height: 608px;
        transform: rotate(-45deg); }
      .landing3__text {
        margin-bottom: 30px; } }

    &-text {
      position: relative;
      z-index: 2; }
    .round-btn {
      position: relative;
      z-index: 2; }

    .constructor-landing__btn {
      margin: 0 auto;
      width: 584px;
      height: 45px;
      justify-content: center;
      svg {
        margin-left: 170px; } }
    @include media ("<desktop") {
      .constructor-landing__btn {
        margin: 50px 0 0 -15px; } }
    @include media ("<tablet") {
      overflow: visible;
      margin-bottom: 30px; } }

  &-swiper-container {
    position: relative;
    width: 100%;
    margin: 100px 0;
    padding: 0 30px;
    overflow: hidden;
    z-index: 2;
    @include media ("<desktop") {
      margin: 0;
      padding: 0 0 80px 0; }


    .constructor-landing__swiper-img {
      display: block;
      height: 175px; }

    .swiper-wrapper {
      align-items: stretch; }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center; }

    .swiper-button-next, .swiper-button-prev {
      border: 1px solid;
      border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
      border-image-slice: 1;
      width: 33px;
      height: 86px;
      margin-top: -43px;
      background: $bg-color;
      z-index: 1;
      @include media ("<desktop") {
        display: none; }
      &:hover {
        background: #e5edff;
        transition: 400ms; }
      svg {
        fill: url(#linear-grad-4); }
      &::after {
        display: none; } }

    .swiper-button-next {
      transform: rotate(180deg);
      right: 1px; }

    .swiper-button-prev {
      left: 0; }

    .swiper-pagination {
      width: 90%;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      @include media (">=desktop") {
        display: none; }
      .swiper-pagination-bullet {
        flex-grow: 1;
        height: 1px;
        margin: 0 8px;
        background-color: $text;
        border-radius: unset;
        &:focus {
          outline: none; }
        &::after {
          content: " ";
          padding: 15px;
          display: block;
          position: relative;
          bottom: 13px; }
        &-active {
          @include gbackground(100%); } } } }



  &__info {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    @include media ("<tablet") {
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-bottom: 90px; } }

  &__info-title {
    position: absolute;
    top: 0;
    left: 410px;
    h2 {
      max-width: 555px;
      margin-bottom: 20px; }
    @include media ("<desktop") {
      position: static;
      margin-bottom: 20px;
      h2 {
        max-width: 80%; } } }

  &__img-block {
    display: flex;
    flex-basis: 50%;
    p {
      margin: auto 50px 113px 40px;
      @include subtitle(1.6rem);
      text-transform: none;
      line-height: 2rem; }
    @include media ("<desktop") {
      img {
        width: 112px;
        align-self: flex-start; }
      p {
        margin-bottom: 40px; } } }

  &__duration {
    max-width: 352px;
    margin: auto 50px -180px auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @include media ("<tablet") {
      margin: unset;
      img {
        width: 100%;
        max-width: 500px; } } }

  &__duration-title {
    @include subtitle(1.8rem);
    font-weight: 900;
    width: 80%;
    align-self: center; }

  &__duration-text {
    width: 80%;
    @include subtitle(1.6rem);
    text-transform: none;
    line-height: 2rem;
    align-self: center;
    @include media ("<tablet") {
      width: 100%;
      padding: 0 10px;
      text-align: center; } }

  &__info-gradient-span {
    padding: 2px 5px;
    @include gbackground(100%);
    display: inline-block;
    @include subtitle(1.6rem);
    text-transform: none;
    color: $white;
    font-weight: 900; }



  &__info-price {
    margin: 59px 0 60px;
    flex-basis: 100%;
    .landing3__info-gradient-span {
      font-size: 3.6rem;
      @include media("<large") {
        font-size: 3rem; }
      @include media("<tablet") {
        font-size: 2.2rem; } }
    @include media ("<tablet") {
      margin-bottom: 40px !important; } }

  &__info-gradient-text {
    span {
      padding: 2px 5px;
      @include gbackground(350px);
      display: inline-block;
      @include subtitle(1.8rem);
      color: $white;
      font-weight: 900; } }


  &__package {
    z-index: 2;
    position: relative;
    @include container--content();
    padding-top: 150px;
    @include media ("<huge") {
      margin: 100px auto 100px; }
    @include media ("<large") {
      margin: 70px auto 70px;
      .landing3__text {
        margin-bottom: 0; } } }

  &__package-title {
    margin-top: 0;
    .landing3__info-gradient-span {
      font-size: 3.6rem;
      text-transform: uppercase; } }

  &__package-img {
    width: 818px;
    margin: 47px 0 75px -146px;
    @include media ("<huge") {
      margin: 30px 0 45px -146px; }
    @include media ("<large") {
      margin: 30px 0 25px -126px; }
    @include media ("<tablet") {
      margin: 40px 0 20px;
      width: 100%; } }


  &__orb-list {
    padding-bottom: 100px; }


  &__text {
    @include subtitle(3.6rem);
    line-height: 4.3rem;
    font-weight: 900;
    letter-spacing: 3.6px;
    @include media("<large") {
      font-size: 3.0rem;
      letter-spacing: 2px;
      line-height: initial; }
    @include media ("<tablet") {
      font-size: 2.2rem;
      letter-spacing: 0.5px;
      line-height: initial; }
    &--smaller {
      font-size: 1.8rem;
      font-weight: 900; }

    &--list {
      @include resetlist();
      margin-bottom: 300px;
      margin-left: 13px;
      li {
        position: relative;
        padding-left: 40px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 7px;
          background-image: url('../img/checkmark_icon.svg');
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 31px; }
        @include media("<large") {
          padding-left: 30px;
          &:after {
            top: 5px;
            height: 25px;
            width: 25px; } }
        @include media("<tablet") {
          padding-left: 25px;
          &:after {
            top: 3px;
            height: 20px;
            width: 20px; } } }
      li + li {
        margin-top: 22px; } }
    @include media("<large") {
      margin-bottom: 100px; } }

  &__plain-title {
    @include subtitle(3.6rem);
    line-height: 4.3rem;
    font-weight: 900;
    letter-spacing: 3.6px;
    @include media("<large") {
      font-size: 3.0rem;
      letter-spacing: 2px;
      line-height: initial; }
    @include media ("<tablet") {
      font-size: 2.2rem;
      letter-spacing: 0.5px;
      line-height: initial; } }

  &__list {
    @include resetlist;
    display: flex;
    flex-flow: row wrap;
    padding-top: 10px;
    justify-content: space-around;
    @include media("<tablet") {
      justify-content: center;
      margin-bottom: 40px; } }


  &__list-item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 24px 15px;
    @include media("<large") {
      margin: 0 20px 15px; }
    @include media("<desktop") {
      margin: 0 10px 45px; }
    @include media("<tablet") {
      flex-basis: 50%;
      margin: 10px 0; }
    &--birthday {
      transform: translate(-18px, -1px);
      @include media("<tablet") {
        order: 1;
        transform: translate(-17px, 15px); } }

    &--love-m {
      transform: translate(-39px, 17px);
      @include media("<tablet") {
        order: 4;
        transform: translate(24px, -25px); } }
    &--friend-m {
      transform: translate(-37px, -23px);
      @include media("<tablet") {
        order: 2;
        transform: translate(-44px, -5px); } }
    &--23feb {
      transform: translate(-8px, -11px);
      .constructor-landing__list-svg-img {
        margin-top: -10px; }
      @include media("<tablet") {
        order: 6;
        transform: translate(7px, -12px); } }
    &--love-f {
      transform: translate(15px, -5px);
      @include media("<tablet") {
        order: 5;
        transform: translate(-35px, -2px); } }
    &--dad {
      transform: translate(16px, 41px);
      @include media("<tablet") {
        order: 3;
        transform: translate(59px, 33px); } }
    &--just {
      transform: translate(11px, -9px);
      .constructor-landing__list-svg-img {
        margin-top: -15px; }
      @include media("<large") {
        transform: translate(0px, 15px); }
      @include media("<tablet") {
        order: 11;
        transform: translate(0px, 19px); } }
    &--car {
      transform: translate(83px, 10px);
      .constructor-landing__list-svg-wrap {
        height: 100px;
        width: 100px; }
      .constructor-landing__list-svg-bg {
        height: 100px;
        width: 100px; }
      @include media("<large") {
        transform: translate(13px, 10px); }
      @include media("<tablet") {
        order: 9;
        transform: translate(32px, 1px); } }
    &--newyear {
      transform: translate(18px, -56px);
      @include media("<large") {
        transform: translate(13px, 10px); }
      @include media("<tablet") {
        order: 12;
        transform: translate(17px, -18px); } }
    &--mom {
      transform: translate(33px, 9px);
      @include media("<large") {
        transform: translate(0px, 10px); }
      @include media("<tablet") {
        order: 8;
        transform: translate(-57px, -14px); } }
    &--8march {
      transform: translate(-13px, -30px);
      @include media("<desktop") {
        transform: translate(0px, 0px); }
      @include media("<tablet") {
        order: 7;
        transform: translate(-17px, 15px); } }
    &--friend-f {
      transform: translate(-45px, -2px);
      @include media("<large") {
        transform: translate(0px, 10px); }
      @include media("<tablet") {
        order: 10;
        transform: translate(20px, -32px); } } }


  &__list-text {
    margin-top: 7px;
    @include subtitle(1.8rem);
    font-weight: 600;
    @include media("<desktop") {
      font-size: 1.6rem; }
    @include media("<tablet") {
      font-size: 1.2rem; } }

  &__list-svg-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 83px;
    &--big-orb {
      width: 135px;
      height: 144px;
      .landing3__list-svg-bg {
        fill: url(#linear-grad-4); }
      .landing3__list-svg-img {
        fill: $white; }
      @include media("<tablet") {
        width: 92px;
        height: 92px;
        .landing3__list-svg-bg {
          width: 92px;
          height: 92px; }
        .landing3__list-svg-img {
          width: 52px;
          height: 52px; } } } }


  &__list-svg-bg {
    fill: transparent;
    stroke: url(#linear-grad-4);
    position: absolute;
    top: 0;
    left: 0; }

  &__list-svg-img {
    position: relative;
    fill: url(#linear-grad-4); }

  &__video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
    width: 100%;
    opacity: 0;
    transition: opacity $transition;
    pointer-events: none;
    iframe {
      width: 100%;
      height: 85vh;
      pointer-events: none;
      @include media("<tablet") {
        height: 70vh; } }
    &--active {
      opacity: 1;
      pointer-events: all;
      iframe {
        pointer-events: all; }
      ~ .landing3__video-backdrop {
        opacity: 0.7;
        pointer-events: all; } } }

  &__video-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    opacity: 0;
    background-color: $black;
    transition: opacity $transition;
    pointer-events: none; }

  &__video-close {
    background: none;
    border: none;
    appearance: none;
    position: absolute;
    top: -38px;
    right: 10px;
    svg {
      height: 30px;
      width: 30px;
      fill: url(#linear-grad); }
    &:focus {
      outline: 0;
      svg {
        stroke: url(#linear-grad-2); } }
    &:active {
      svg {
        transform: translateY(-2px); } } } }

.round-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  padding: 27px 35px 27px 28px;
  text-decoration: none;
  background-image: linear-gradient(90deg, #B434A3 0%, #40196F 100%);
  border-radius: 45px;
  border: 2px solid $white;
  span {
    @include subtitle(1.8rem);
    color: $white;
    font-weight: 500;
    letter-spacing: 0.7px;
    text-transform: uppercase; }
  svg {
    margin-left: 30px;
    fill: $white;
    transform: translateX(0);
    transition: transform $transition; }
  &:hover {
    svg {
      transform: translateX(15px); } }
  @include media("<large") {
    span {
      font-size: 1.6rem; } }
  @include media("<tablet") {
    padding: 20px 25px 20px 21px;
    span {
      font-size: 1.2rem; } } }


.landing3__gradient-highlight {
  padding: 3px 15px;
  @include gbackground(100%);
  display: inline-block;
  @include subtitle(3.6rem);
  color: $white;
  font-weight: 900;
  @include media("<large") {
    font-size: 3rem; }
  @include media("<tablet") {
    width: min-content;
    font-size: 2.2rem; } }
