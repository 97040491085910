.pcalculator {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__text {
    position: relative;
    z-index: 2;
    h2 {
      margin-bottom: 0;
      font-size: 3.4rem;
      letter-spacing: 0.2rem;
      font-weight: 900;
      @include media("<desktop") {
        font-size: 2.2rem; } }

    h2 + p {
      color: $text;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0.1rem;
      margin-top: 0;
      text-transform: uppercase;
      @include media("<desktop") {
        font-size: 1.4rem;
        margin-top: 10px; } } }

  &__subtitle {
    position: relative;
    z-index: 2; }

  .pcalculator__title {
    line-height: unset;
    padding: 10px 20px;
    font-size: 2rem; }

  &__imgs {
    position: relative;
    z-index: 1;
    @include media("<large") {
      display: none; } }

  &__bg {
    position: absolute;
    top: 44%;
    left: -88%;
    transform: translateY(-50%); }

  &__img {
    position: relative; }


  &__calculator {
    z-index: 2;
    width: 50%;
    @include media("<large") {
      width: 100%; } }

  &__range {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center; }

  &__range-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 29px;
    @include media("<desktop") {
      font-size: 1.4rem;
      margin-top: 10px; } }

  &__current-value {
    display: inline-block;
    margin: 0 auto;
    @include subtitle(4.8rem);
    color: transparent;
    font-weight: 900;
    max-height: unset;
    @include gbackground();
    -webkit-background-clip: text;
    background-clip: text;
    @include media("<desktop") {
      font-size: 3.6rem; } }

  &__values {
    display: flex;
    flex-flow: row wrap;
    @include media("<desktop") {
      justify-content: space-between; } }

  &__info-text {
    flex-basis: 50%;
    margin-top: 52px;
    @include media("<desktop") {
      margin-top: 25px;
      padding: 5px; } }

  &__info-text--profit {
    @include gbackground();
    margin: 35px 20px 0 auto;
    padding: 18px 20px;
    @include media("<desktop") {
      padding: 12px 15px;
      margin-top: 17px;
      margin-right: 10px; }

    .pcalculator__info-title, .pcalculator__info-value {
      color: $white; } }


  &__info-title {
    display: flex;
    align-items: flex-start;
    min-height:  60px;
    max-width: 70%;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $text;
    @include media("<desktop") {
      font-size: 1.2rem; } }


  &__info-value {
    font-size: 3.6rem;
    font-weight: 900;
    color: $text;
    @include media("<desktop") {
      font-size: 2rem; } }



  &__range {
    .rs-scale {
      display: flex;
      span {
        z-index: 1;
        ins {
          opacity: 0; }
        &:last-child, &:first-child {
          ins {
            font-size: 1.4rem;
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            color: $text;
            opacity: 1;
            margin-left: 0 !important; } }
        &:first-child {
          ins {
            margin-left: 0 !important; } }
        &:last-child {
          ins {
            margin-left: -58px !important; } }
        &:after {
          content: "";
          position: absolute;
          top: -4px;
          display: block;
          height: 10px;
          width: 1px;
          background-color: lighten($text, 60%); } } }
    .rs-tooltip {
      background: transparent;
      border: none; }
    .rs-bg, .rs-selected {
      z-index: 2;
      height: 3px; }
    .rs-bg {
      border: none;
      background-color: lighten($text, 60%); }
    .rs-selected {
      border: none;
      background-color: transparent;
      @include gbackground(); }
    .rs-pointer {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -3px;
      background: transparent;
      @include gbackground(200%, -100%);
      z-index: 3;
      border: none;
      box-shadow: none;
      &:before, &:after {
        display: none; } } }

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3rem;
    margin-top: 35px;
    padding: 24px;
    max-width: calc(100% - 20px);
    @include media ("<desktop") {
      margin: 40px auto 0; }
    @include media ("<tablet") {
      font-size: 1.6rem;
      margin-top: 25px; }
    @include media ("<tiny") {
      width: 100%;
      font-size: 1.2rem;
      padding: 15px; } } }
