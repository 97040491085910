.cart {
  @include container--content();
  position: relative;

  &__tabs {
    position: relative;
    @include media("<desktop") {
      display: flex;
      justify-content: center; } }



  &__tab-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    &--active {
      opacity: 1;
      max-height: unset;
      padding: unset;
      pointer-events: all;
      transition: opacity $transition; }
    &--1 {
      @include media("<desktop") {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; } } }


  &__wrapper {
    display: flex;
    margin-top: 21px;
    &--tab-2 {
      margin-top: 54px; }
    @include media("<desktop") {
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      max-width: 600px; }
    @include media("<tablet") {
      max-width: unset;
      &--tab-2 {
        margin-top: 24px; } } }


  &__items {
    margin-top: 29px;
    flex-grow: 1;
    max-width: 645px;
    @include media("<tablet") {
      width: 100%; } }

  &__item {
    position: relative;
    display: flex; }

  &__item--box {
    flex-flow: row wrap;
    @include media("<tablet") {
      align-items: flex-start; }
    .cart__item-close {
      margin-left: auto; }

    .cart__item-list {
      @include resetlist();
      margin-left: auto;
      flex-basis: 50%;
      padding: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height $transition $transition, opacity $transition, padding $transition $transition;
      &--active {
        opacity: 1;
        max-height: 1000px;
        overflow: visible;
        padding-bottom: 15px;
        transition: max-height $transition, opacity $transition $transition; }
      @include media("<desktop") {
        margin-top: 20px;
        flex-basis: 70%; }
      @include media("<tablet") {
        flex-basis: 90%; }
      @include media("<tiny") {
        flex-basis: 84%; } }

    .cart__item-list-collapse {
      width: 0;
      height: 0;
      margin-right: 20px;
      border-top: 13px solid $gradient-end;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      transform: scaleY(1);
      transition: transform $transition;
      &--active {
        transform: scaleY(-1); } }

    .cart__item-list-item + .cart__item-list-item {
      margin-top: 10px; }

    .cart__item-list-item {
      display: flex;
      align-items: center;
      figure {
        margin: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center; }
      img {
        height: 100%;
        object-fit: cover; } }

    .cart__item-list-text {
      margin-left: 10px;
      display: flex;
      flex-flow: column nowrap; }

    .cart__item-list-title {
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 1.4rem; }

    .cart__item-list-code {
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-size: 1.2rem; } }



  &__item + &__item {
    margin-top: 37px;
    &:before {
      content: "";
      position: absolute;
      right: 6px;
      top: -22px;
      width: calc(100% - 140px);
      height: 1px;
      @include gbackground(100%); }
    @include media("<tablet") {
      &:before {
        width: 100%; } } }


  &__item-img-wrap {
    margin: 0 0 0 11px;
    max-width: 106px;
    @include media("<tablet") {
      width: 62px;
      max-width: unset;
      flex-shrink: 0;
      display: flex;
      align-items: center; } }

  &__item-img {
    width: 100%; }

  &__item-text {
    margin: 8px 23px 0 17px;
    display: flex;
    max-width: 300px;
    flex-flow: column nowrap;
    @include media("<tablet") {
      flex-basis: 90%;
      max-width: unset;
      margin: 8px -150px 0 10px; } }

  &__item-name {
    @include subtitle(1.8rem);
    font-weight: 900;
    line-height: 100%;
    @include media("<tablet") {
      font-size: 1.2rem; } }

  &__item-price {
    position: relative;
    margin-top: 2px;
    @include subtitle(2.4rem);
    font-weight: 400;
    text-transform: none;
    @include media("<tablet") {
      font-size: 1.4rem;
      order: 1;
      margin: auto 0 7px 0; }

    &--discount {
      margin-left: 15px; }

    &--crossed {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg);
        display: block;
        height: 2px;
        width: 105%;
        background-color: $text; }
      @include media("<tablet") {
        &:after {
          height: 1px; } } } }

  &__item-code {
    margin-top: 10px;
    @include subtitle(1.4rem);
    @include media("<tablet") {
      font-size: 0.8rem;
      margin-top: 5px; } }


  &__item-ui {
    width: 180px;
    padding-top: 10px;
    margin-left: auto;
    @include media("<tablet") {
      width: 130px;
      margin-top: 50px; } }


  &__item-ui-text {
    display: block;
    @include subtitle(1.8rem);
    font-weight: 900;
    text-align: center;
    margin-bottom: 17px;
    @include media("<tablet") {
      display: none; } }

  &__item-ui-wrap {
    display: flex; }



  &__item-number {
    @include resetinteractive();
    margin: 0;
    display: block;
    flex-shrink: 1;
    max-width: 38%;
    flex-basis: 38%;
    @include subtitle(2.4rem);
    font-weight: 900;
    text-align: center;
    background-color: $white;
    &:focus, &:active {
      outline: 1px solid darken($white, 20%); }
    @include media("<tablet") {
      font-size: 1.2rem;
      width: 47px;
      flex-basis: unset; } }

  &__item-btn {
    @include resetinteractive();
    margin: 0;
    padding: 12px 5px 12px 22px;
    flex-basis: 25%;
    flex-shrink: 0;
    background-color: $white;
    cursor: pointer;
    z-index: 3;
    transition: background-color $transition/3, opacity $transition;
    &:active {
      outline: 0;
      background-color: darken($white, 20%); }
    &:focus {
      outline: 1px solid darken($white, 10%); }
    &:hover {
      svg {
        transform: rotate(180deg) translateX(2px); } }
    svg {
      transform: rotate(180deg);
      transition: transform $transition; }
    &--disabled {
      opacity: 0.3;
      background-color: darken($white, 30%);
      pointer-events: none; }
    &--add {
      padding: 12px 22px 12px 5px;
      svg {
        transform: rotate(0); }
      &:hover {
        svg {
          transform: rotate(0deg) translateX(2px); } } }
    @include media("<tablet") {
      padding: 9px 5px;
      svg {
        max-width: 23px; } } }

  &__item-close {
    @include resetinteractive();
    margin: 5px 0 0 68px;
    align-self: flex-start;
    svg {
      transform: translateY(0);
      transition: transform $transition/3; }
    &:focus {
      outline: 0;
      svg {
        stroke: url(#linear-grad-2); } }
    &:active {
      svg {
        transform: translateY(-2px); } }
    @include media("<tablet") {
      position: absolute;
      top: 0;
      right: 5px;
      margin-left: 0;
      svg {
        width: 20px;
        height: 20px; } } }

  &__cart-empty {
    display: block;
    margin-top: 0;
    font-size: 2.4rem;
    text-transform: uppercase;
    color: lighten($text, 40%);
    opacity: 0;
    &--active {
      margin-top: 50px;
      opacity: 1;
      transition: opacity $transition; } }

  &__total-price {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    width: 400px;
    height: 227px;
    padding: 27px 59px 35px 69px;
    @include media("<desktop") {
      width: 100%;
      height: unset;
      margin-left: unset; }
    @include media("<tablet") {
      margin-top: 50px;
      padding: 0; } }

  &__price-border {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    @include media("<large") {
      display: none; } }

  &__total-price-title {
    display: block;
    @include subtitle(2.4rem);
    font-weight: 900;
    @include media("<tablet"); }

  &__total-price-wrapper {
    display: flex;
    justify-content: space-between; }

  &__total-price-gain {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    @include subtitle(1.6rem);
    font-weight: 900;
    opacity: 0;
    pointer-events: none;
    &:after {
      content: "";
      @include gbackground (100%);
      position: absolute;
      bottom: -7px;
      display: block;
      height: 2px;
      width: 100%;
      background-color: $text; }
    @include media("<tablet") {
      font-size: 1.2rem; }

    &--active {
      opacity: 1;
      pointer-events: all; } }


  &__total-price-value {
    display: inline-block;
    margin-top: 3px;
    position: relative;
    @include subtitle(2.4rem);
    text-transform: none;
    @include media("<tablet") {
      margin-top: unset; }
    &--discount {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg);
        display: block;
        height: 2px;
        width: 105%;
        background-color: $text; } } }

  &__total-price-discount {
    opacity: 0;
    pointer-events: none;
    @include gbackground (100%);
    @include subtitle(2.4rem);
    display: inline-block;
    padding: 3px 7px;
    margin-left: auto;
    color: $white;
    text-transform: uppercase;
    font-weight: 900;
    &--active {
      opacity: 1;
      pointer-events: all; } }

  &__total-price-proceed-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 23px;
    padding: 7px 17px 7px 32px;
    &:hover {
      svg {
        transform: translateX(7px); } }
    @include media("<tablet") {
      margin-top: 25px; }

    span {
      letter-spacing: 0.6px; }
    svg {
      position: relative; } }

  &__delivery-info {
    margin-top: 110px;
    max-width: 665px;
    @include media("<tablet") {
      margin-top: 30px; } }

  &__delivery-title {
    @include subtitle(3.6rem);
    font-weight: 900;
    @include media("<tablet") {
      font-size: 2.2rem; } }

  &__delivery-text {
    p {
      font-size: 1.8rem;
      line-height: 3rem; }
    p + p {
      margin-top: 38px; }
    @include media("<tablet") {
      p {
        font-size: 1.4rem;
        line-height: 2rem; }
      p + p {
        margin-top: 15px; } } } }



