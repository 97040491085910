.constructor-landing {
  @include container--content();


  &__main-title {
    color: $white;
    font-size: 4.8rem;
    line-height: 5.5rem;
    font-weight: 900;
    text-transform: uppercase;
    margin: 10px 0;
    @include media ("<huge") {
      font-size: 4.0rem; // 3.6rem
      line-height: 4rem; }
    @include media ("<large") {
      font-size: 3.5rem; // 3.6rem
      line-height: 4rem; }
    @include media ("<desktop") {
      font-size: 3.1rem;
      line-height: 3.8rem; }
    @include media ("<tablet") {
      margin-top: 0;
      font-size: 2.5rem;
      line-height: 3rem; }
    @media screen and (max-width: 614px) {
      font-size: 2rem;
      line-height: 2.3rem; }
    @media screen and (max-width: 500px) {
      font-size: 2.5rem;
      line-height: 3.2rem; }
    @include media ("<=tiny") {
      font-size: 2rem;
      line-height: 2.7rem; }

    span {
      @media screen and (max-width: 500px) {
        @include gbackground (350px);
        span {
          &::after {
            white-space: break-spaces;
            content: "\A"; } } }
      @include media ("<tiny") {
        @include gbackground (250px); } }

    > span {
      &:first-child {
        padding-top: 12px;
        padding-bottom: 0;
        z-index: 2; }
      &:nth-child(2) {
        padding: 10px;
        z-index: 1;
        @include media ("<huge") {
          padding: 0 15px;
          z-index: 3; }
        @include media ("<desktop") {
          z-index: 3; } }
      &:last-child {
        padding-bottom: 12px;
        padding-top: 0;
        z-index: 2; }
      &:first-child, &:nth-child(2), &:last-child {
        position: relative;
        @include media ("<huge");

        @include media ("<large") {
          padding: 5px; }
        @include media ("<tablet") {
          padding: 0;
          span {
            padding: 5px; } } } } }

  &__subtitle {
    position: relative;
    color: $white;
    font-size: 4.8rem;
    line-height: 5.5rem;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 4;

    @include media ("<large") {
      font-size: 3.5rem; // 3.6rem
      line-height: 4rem; }
    @include media ("<desktop") {
      font-size: 3.1rem;
      line-height: 3.8rem; }
    @include media ("<tablet") {
      font-size: 2.5rem;
      line-height: 3rem; }
    @media screen and (max-width: 614px) {
      font-size: 2rem;
      line-height: 2.3rem; }
    @media screen and (max-width: 500px) {
      font-size: 2.5rem;
      line-height: 3.2rem; }
    @include media ("<=tiny") {
      font-size: 2rem;
      line-height: 2.7rem; }

    span {
      @media screen and (max-width: 500px) {
        @include gbackground (350px);
        span {
          &::after {
            white-space: break-spaces;
            content: "\A"; } } }
      @include media ("<tiny") {
        @include gbackground (250px); } } }

  &__title-line {
    @include gbackground (1130px);
    padding: 0 15px;
    &::after {
      white-space: break-spaces;
      content: "\A";
      @media screen and (max-width: 500px) {
        display: none; } }

    @include media ("<desktop") {
      @include gbackground (900px); }
    @include media ("<tablet") {
      @include gbackground (600px);
      padding: 5px; }
    @media screen and (max-width: 614px) {
      @include gbackground (500px); }
    @media screen and (max-width: 500px) {
      padding: 0;
      span {
        padding: 5px; } } }


  &__header {
    margin-bottom: 130px;
    @include media ("<tablet") {
      margin-bottom: 50px; } }

  &__landing-price {
    @include gbackground(400px);
    display: inline-block;
    padding: 10px;
    margin-top: 20px;
    padding-right: 29px;
    .price--crossed {
      margin-left: 20px; }
    .price + .price {
      margin-left: 54px; }

    @include media ("<tablet") {
      margin-top: 0;
      padding: 5px 15px;
      .price--crossed {
        margin-left: 0; }
      .price + .price {
        margin-left: 15px; } } }

  &__landing-images {
    display: flex;
    align-items: center;
    margin: -80px 0 -168px -65px;
    @include media("<large") {
      margin: -90px 0 -138px -35px; }
    @include media("<desktop") {
      margin: -30px 0 -60px -5px;
      justify-content: space-around; }
    @include media("<tablet") {
      display: none; } }


  &__landing-img--1 {
    width: 550px;
    margin-top: -40px;
    @include media("<large") {
      margin-top: unset;
      width: 500px; }
    @include media("<desktop") {
      width: 350px; } }

  &__landing-img--2 {
    margin-left: -20px;
    margin-top: 25px;
    width: 650px;
    @include media("<large") {
      width: 600px; }
    @include media("<desktop") {
      width: 450px; } }

  &__btn {
    width: 396px;
    height: 62px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 1.8rem;
      letter-spacing: 1px;
      margin-left: 42px; }
    svg {
      margin-left: 55px; }
    svg {
      margin-right: 15px; }
    @include media("<large") {
      width: 350px;
      height: 50px;
      span {
        font-size: 1.6rem;
        letter-spacing: 0.7px;
        margin-left: 32px; }
      svg {
        margin-right: 0;
        margin-left: 35px; } }
    @include media("<desktop") {
      width: calc(100% + 30px);
      margin-left: -15px;
      max-width: 500px;
      justify-content: space-around;
      span {
        font-size: 1.6rem;
        letter-spacing: 0.7px;
        margin-left: 15px;
        white-space: nowrap; }
      svg {
        margin-right: 10px; } } }


  .constructor-landing__landing-mob-slider {
    @include media (">=tablet") {
      display: none; }
    @include media ("<tablet") {
      width: 100vw;
      margin: 0 -15px 0; }
    .swiper-container {
      width: 100%;
      margin-top: 60px;
      @include media (">=tablet") {
        display: none; } }


    .swiper-wrapper {
      display: flex;
      align-items: center; }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      width: 100%;
      &:nth-child(2) {
        position: relative;
        top: 20px;
        @include media ("<phone") {
          top: 40px; } }
      p {
        text-align: center;
        margin: 40px 0; } }

    .swiper-img-slide-1 {
      width: 100%; }


    .swiper-img-slide-2 {
      max-height: 210px;
      margin-top: -28px;
      margin-bottom: 20px; }


    .swiper-pagination {
      bottom: 25px;

      .swiper-pagination-bullet {
        width: 30%;
        height: 1px;
        margin: 0 5%;
        background-color: $text;
        border-radius: unset;
        &:focus {
          outline: none; }
        &::after {
          content: " ";
          padding: 15px;
          display: block;
          position: relative;
          bottom: 13px; }
        &-active {
          @include gbackground(100%); } } } }


  &__text {
    @include subtitle(3.6rem);
    line-height: 4.3rem;
    font-weight: 900;
    letter-spacing: 3.6px;
    @include media("<large") {
      font-size: 3.0rem;
      letter-spacing: 2px;
      line-height: initial; }
    @include media ("<tablet") {
      font-size: 2.2rem;
      letter-spacing: 0.5px;
      line-height: initial; }

    &--list {
      @include resetlist();
      li {
        position: relative;
        padding-left: 40px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 7px;
          background-image: url('../img/checkmark_icon.svg');
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 31px; }
        @include media("<large") {
          padding-left: 30px;
          &:after {
            top: 5px;
            height: 25px;
            width: 25px; } }
        @include media("<tablet") {
          padding-left: 25px;
          &:after {
            top: 3px;
            height: 20px;
            width: 20px; } } }
      li + li {
        margin-top: 10px; } } }


  &__slider-text {
    max-width: 84%;
    @include media("<large") {
      br {
        display: none; } } }

  &__price-span {
    @include gbackground (100%);
    display: inline-block;
    padding: 3px 7px;
    margin-left: -5px;
    color: $white;
    text-transform: uppercase;
    font-weight: 900; }


  &__slider {
    overflow: hidden;
    padding-bottom: 30px;
    margin-bottom: 77px;
    .constructor-landing__btn {
      margin: 0 auto;
      width: 584px;
      height: 45px;
      justify-content: center;
      svg {
        margin-left: 170px; } }
    @include media ("<desktop") {
      .constructor-landing__btn {
        margin: 50px 0 0 -15px; } }
    @include media ("<tablet") {
      overflow: visible;
      margin-bottom: 30px; } }

  &-swiper-container {
    position: relative;
    width: 100%;
    margin: 100px 0;
    padding: 0 30px;
    @include media ("<desktop") {
      margin: 0;
      padding: 0; }


    .constructor-landing__swiper-img {
      display: block;
      height: 175px; }

    .swiper-wrapper {
      align-items: stretch; }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center; }

    .swiper-button-next, .swiper-button-prev {
      border: 1px solid;
      border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
      border-image-slice: 1;
      width: 33px;
      height: 86px;
      margin-top: -43px;
      background: $bg-color;
      z-index: 1;
      @include media ("<desktop") {
        display: none; }
      &:hover {
        background: #e5edff;
        transition: 400ms; }
      svg {
        fill: url(#linear-grad-4); }
      &::after {
        display: none; } }

    .swiper-button-next {
      transform: rotate(180deg);
      right: 1px; }

    .swiper-button-prev {
      left: 0; }

    .swiper-pagination {
      width: 90%;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      @include media (">=desktop") {
        display: none; }
      .swiper-pagination-bullet {
        flex-grow: 1;
        height: 1px;
        margin: 0 8px;
        background-color: $text;
        border-radius: unset;
        &:focus {
          outline: none; }
        &::after {
          content: " ";
          padding: 15px;
          display: block;
          position: relative;
          bottom: 13px; }
        &-active {
          @include gbackground(100%); } } } }


  &__list {
    @include resetlist;
    display: flex;
    flex-flow: row wrap;
    padding-top: 10px;
    justify-content: space-around;
    @include media("<tablet") {
      justify-content: center; } }


  &__list-item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 24px 15px;
    @include media("<large") {
      margin: 0 20px 15px; }
    @include media("<desktop") {
      margin: 0 10px 15px; }
    @include media("<tablet") {
      flex-basis: 50%;
      margin: 10px 0; }
    &--birthday {
      transform: translate(-18px, -1px);
      @include media("<tablet") {
        order: 1;
        transform: translate(-17px, 15px); } }

    &--love-m {
      transform: translate(-39px, 17px);
      @include media("<tablet") {
        order: 4;
        transform: translate(24px, -25px); } }
    &--friend-m {
      transform: translate(-37px, -23px);
      @include media("<tablet") {
        order: 2;
        transform: translate(-44px, -5px); } }
    &--23feb {
      transform: translate(-8px, -11px);
      .constructor-landing__list-svg-img {
        margin-top: -10px; }
      @include media("<tablet") {
        order: 6;
        transform: translate(7px, -12px); } }
    &--love-f {
      transform: translate(15px, -5px);
      @include media("<tablet") {
        order: 5;
        transform: translate(-35px, -2px); } }
    &--dad {
      transform: translate(16px, 41px);
      @include media("<tablet") {
        order: 3;
        transform: translate(59px, 33px); } }
    &--just {
      transform: translate(11px, -9px);
      .constructor-landing__list-svg-img {
        margin-top: -15px; }
      @include media("<large") {
        transform: translate(0px, 15px); }
      @include media("<tablet") {
        order: 11;
        transform: translate(0px, 19px); } }
    &--car {
      transform: translate(83px, 10px);
      .constructor-landing__list-svg-wrap {
        height: 100px;
        width: 100px; }
      .constructor-landing__list-svg-bg {
        height: 100px;
        width: 100px; }
      @include media("<large") {
        transform: translate(13px, 10px); }
      @include media("<tablet") {
        order: 9;
        transform: translate(32px, 1px); } }
    &--newyear {
      transform: translate(18px, -56px);
      @include media("<large") {
        transform: translate(13px, 10px); }
      @include media("<tablet") {
        order: 12;
        transform: translate(17px, -18px); } }
    &--mom {
      transform: translate(33px, 9px);
      @include media("<large") {
        transform: translate(0px, 10px); }
      @include media("<tablet") {
        order: 8;
        transform: translate(-57px, -14px); } }
    &--8march {
      transform: translate(-13px, -30px);
      @include media("<desktop") {
        transform: translate(0px, 0px); }
      @include media("<tablet") {
        order: 7;
        transform: translate(-17px, 15px); } }
    &--friend-f {
      transform: translate(-45px, -2px);
      @include media("<large") {
        transform: translate(0px, 10px); }
      @include media("<tablet") {
        order: 10;
        transform: translate(20px, -32px); } } }


  &__list-text {
    margin-top: 7px;
    @include subtitle(1.8rem);
    font-weight: 600;
    @include media("<desktop") {
      font-size: 1.6rem; }
    @include media("<tablet") {
      font-size: 1.2rem; } }

  &__list-svg-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 83px;
    &--big-orb {
      width: 135px;
      height: 144px;
      .constructor-landing__list-svg-bg {
        fill: url(#linear-grad-4); }
      .constructor-landing__list-svg-img {
        fill: $white; }
      @include media("<tablet") {
        width: 92px;
        height: 92px;
        .constructor-landing__list-svg-bg {
          width: 92px;
          height: 92px; }
        .constructor-landing__list-svg-img {
          width: 52px;
          height: 52px; } } } }


  &__list-svg-bg {
    fill: transparent;
    stroke: url(#linear-grad-4);
    position: absolute;
    top: 0;
    left: 0; }

  &__list-svg-img {
    position: relative;
    fill: url(#linear-grad-4); }





  &__filler-1 {
    display: flex;
    position: relative;
    z-index: 3;
    margin-top: -45px;
    @include media("<desktop") {
      margin-top: unset;
      flex-flow: column nowrap; }

    .constructor-landing__text {
      margin-top: 237px;
      @include media("<desktop") {
        margin-top: 30px; } } }

  &__promo-img {
    display: block;
    margin-right: calc((1176px - 100vw)/2);
    width: 831px;
    @include media("<huge") {
      margin-right: calc((1450px - 100vw)/2);
      margin-left: -100px;
      width: 700px; }
    @include media("<large") {
      width: 600px;

      margin-right: calc((1194px - 100vw)/2); }
    @include media("<desktop") {
      height: auto;
      margin-right: -15px;
      width: 100%;
      max-width: 700px;
      align-self: flex-end;
      img {
        display: block;
        width: 100%;
        max-width: 700px; } } }


  &__filler-2 {
    display: flex;
    position: relative;
    z-index: 1;
    @include media("<desktop") {
      flex-flow: column nowrap;
      align-items: center; } }


  &__filler-2-title {
    position: absolute;
    right: -52px;
    top: 56px;
    z-index: 2;
    width:  566px;
    @include media("<desktop") {
      position: static; }
    @include media("<tablet") {
      width: 100%; } }


  &__images {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    margin-left: -80px;
    @include media("<large") {
      margin-left: -40px; }
    @include media("<desktop") {
      flex-flow: column nowrap;
      margin-left: unset; } }

  &__box-img {
    position: relative;
    display: block;
    width: 764px;
    z-index: 3;
    img {
      display: block;
      width: 100%; }
    @include media("<large") {
      width: 550px; }
    @include media("<tablet") {
      width: 100%;
      max-width: 500px; } }


  &__bgsplash-img {
    display: block;
    width: 1835px;
    position: absolute;
    left: 31%;
    top: 36%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @include media("<desktop") {
      left: 50%;
      top: 30%; }
    @include media("<tablet") {
      max-width: 500px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        max-width: 700px; } } }


  &__box-tag {
    @include gbackground(700px);
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: -128px;
    padding-left: 66px;
    z-index: 2;
    width: 629px;
    height: 118px;
    @include media("<large") {
      width: 429px;
      height: 78px; }

    @include media("<desktop") {
      padding-left: unset;
      margin-top: -30px;
      margin-left: unset; }
    @include media("<tablet") {
      width: 100%;
      height: 32px; } } }

.price, .constructor-landing__box-text {
  @include subtitle(3.6rem);
  font-weight: 900;
  color: $white;
  @include media("<large") {
    font-size: 3.0rem; }
  @include media("<desktop") {
    font-size: 2.4rem; }
  @include media("<tablet") {
    font-size: 2.0rem; } }

.price--crossed {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-13deg);
    display: block;
    height: 2px;
    width: 105%;
    background-color: $text; } }

.constructor-landing__box-text {
  text-transform: none; }

