.landing-lottery {

  &__section-title {
    position: relative;
    z-index: 2;
    padding: 5px 19px;
    @include gbackground(100%);
    display: inline-block;
    @include subtitle(4.8rem);
    color: $white;
    font-weight: 900;
    margin-bottom: 30px;
    @include media ("<large") {
      font-size: 3.5rem; // 3.6rem
      line-height: 4rem; }
    @include media ("<desktop") {
      font-size: 3.1rem;
      line-height: 3.8rem; }
    @include media ("<tablet") {
      font-size: 2.5rem;
      line-height: 3rem; }
    @media screen and (max-width: 614px) {
      padding: 3px 5px;
      font-size: 2rem;
      line-height: 2.3rem; }
    @media screen and (max-width: 500px) {
      font-size: 2rem;
      line-height: 3.2rem; }
    @include media ("<=tiny") {
      font-size: 2rem;
      line-height: 2.7rem; } }

  &__section-title--two-line {
    @media screen and (max-width: 500px) {
      padding: 1px 5px;
      font-size: 1.6rem;
      line-height: 3.2rem; } }

  &__segmented-title {
    color: $white;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    margin: 10px 0;
    @include media ("<desktop") {
      font-size: 1.4rem;
      line-height: 1.6rem; }


    span {
      @media screen and (max-width: 768px) {
        @include gbackground (350px);
        span {
          &::after {
            white-space: break-spaces;
            content: "\A"; } } }
      @include media ("<tiny") {
        @include gbackground (350px); } }

    > span {
      &:first-child {
        padding-top: 5px;
        padding-bottom: 0;
        z-index: 2; }
      &:last-child {
        padding-bottom: 5px;
        padding-top: 0;
        z-index: 2; }
      &:first-child, &:nth-child(2), &:last-child {
        position: relative;
        @include media ("<huge");

        @include media ("<tablet") {
          padding: 0;
          span {
            padding: 1px 3px; } } } } }

  &__segmented-title-line {
    @include gbackground (630px);
    padding: 0 7px;
    &::after {
      white-space: break-spaces;
      content: "\A";
      @media screen and (max-width: 768px) {
        display: none; } }
    @include media ("<tablet") {
      @include gbackground (630px);
      padding: 5px; }
    @media screen and (max-width: 614px) {
      @include gbackground (500px); }
    @media screen and (max-width: 500px) {
      padding: 0;
      span {
        padding: 5px; } } }


  &__section-subtitle {
    position: relative;
    z-index: 3;
    @include subtitle(3.6rem);
    letter-spacing: 3.3px;
    text-shadow: 0 0 3px $bg-color, 0 0 3px $bg-color;
    @include media ("<large") {
      font-size: 3rem; // 3.6rem
      margin-top: 0; }
    @include media ("<desktop") {
      font-size: 2.8rem; }
    @include media ("<tablet") {
      font-size: 2.5rem; }
    @media screen and (max-width: 614px) {
      font-size: 2rem; }
    @media screen and (max-width: 500px) {
      font-size: 2.5rem; }
    @include media ("<=tiny") {
      font-size: 2rem; } }

  &__steps {
    position: relative;
    @include container--lottery();
    z-index: 1;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1015px;
      left: -433px;
      z-index: 1;
      transform: rotate(204deg);
      background-image: url('../img/landing-lottery_steps-repeating-bg.jpg');
      width: 800px;
      height: 966px;
      background-repeat: no-repeat; }
    @include media("retina2x") {
      &::after {
        background-size: cover;
        background-image: url('../img/landing-lottery_steps-repeating-bg@2x.jpg'); } }
    @include media("retina2x", "<tablet") {
      &::after {
        background-size: cover;
        width: 600px;
        height: 724px;
        bottom: -1020px;
        left: -383px;
        background-image: url('../img/landing-lottery_steps-repeating-bg-sm@2x.jpg'); } } }




  &__steps-wrapper {
    position: relative;
    z-index: 2;
    @include resetlist();
    height: 1066px;
    @include media ("<desktop") {
      height: unset; } }

  &__step--1 {
    top: 26px;
    left: 22px;
    &::after {
      content: "";
      position: absolute;
      z-index: 3;
      left: 74%;
      top: 48px;
      display: block;
      width: 372px;
      height: 226px;
      background-image: url('../img/landing-lottery_steps-arrow-1.svg');
      background-repeat: no-repeat; }
    .landing-lottery__step-bg-img {
      top: 63%;
      left: 169px;
      transform: translate(-50%, -50%); }

    @include media ("<desktop") {
      flex-flow: column nowrap;
      .landing-lottery__step-text-block {
        order: 0;
        margin: 0; }
      .landing-lottery__step-img {
        order: 1; }
      .landing-lottery__step-bg-img {
        top: 63%;
        left: 50%; }
      .landing-lottery__step-text {
        margin-left: 10px;
        max-width: 210px; } } }

  &__step--2 {
    top: -96px;
    right: -28px;
    flex-flow: column nowrap;
    &::after {
      content: "";
      position: absolute;
      z-index: 3;
      right: 113%;
      bottom: -32px;
      display: block;
      width: 552px;
      height: 230px;
      background-image: url('../img/landing-lottery_steps-arrow-2.svg');
      background-repeat: no-repeat; }
    .landing-lottery__step-text-block {
      order: 0; }
    .landing-lottery__step-img {
      order: 1; }
    .landing-lottery__step-bg-img {
      top: 79%;
      left: 227px;
      transform: translate(-50%, -50%); }
    .landing-lottery__step-text {
      max-width: 300px; }

    @include media ("<large") {
      top: 296px; }
    @include media ("<desktop") {
      .landing-lottery__step-img {
        display: block;
        img {
          max-width: 100%; } }
      .landing-lottery__step-bg-img {
        display: none; }
      .landing-lottery__step-number {
        order: 1; }
      .landing-lottery__step-text {
        order: 0;
        margin-right: 10px;
        max-width: 195px;
        text-align: right; } } }

  &__step--3 {
    top: 523px;
    left: -28px;
    flex-flow: column nowrap;

    .landing-lottery__step-number {
 }      //margin-bottom: -54px
    .landing-lottery__step-text {
      max-width: 300px;
      margin-bottom: 0; }
    .landing-lottery__step-text-gradient {
      margin-left: 37px; }
    @include media ("<desktop") {
      flex-flow: column nowrap;
      .landing-lottery__step-text {
        max-width: 210px; }
      .landing-lottery__step-text-gradient {
        margin-left: unset; } }
    @include media ("<tablet") {
      .landing-lottery__step-text-gradient {
        max-width: 290px; } } }

  &__step {
    position: absolute;
    display: flex;
    list-style: none;
    @include media ("<large") {
      &:after {
        display: none; } }
    @include media ("<desktop") {
      position: relative;
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;
      align-items: center;
      width: 100%; } }

  &__step-img {
    position: relative;
    z-index: 2;
    @include media ("<desktop") {
      .landing-lottery__step-img {
        display: block;
        img {
          max-width: 100%; } } } }


  &__step-bg-img {
    position: absolute;
    z-index: 1; }


  &__step-text-block {
    margin-left: 37px;
    margin-top: 92px;
    @include media ("<desktop") {
      display: flex;
      align-items: center;
      width: max-content; } }

  &__step-number {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: -74px;
    @include subtitle(25rem);
    font-weight: 900;
    color: $white;
    text-shadow: 0 4px 45px #B195EF;
    @include media ("<desktop") {
      font-size: 12rem;
      margin-bottom: unset; } }

  &__step-text {
    position: relative;
    z-index: 2;
    @include subtitle(1.8rem);
    font-weight: 900;
    line-height: 121%;
    max-width: 250px;
    @include media ("<desktop") {
      font-size: 1.4rem; } }

  &__text-highlight {
    padding: 2px 5px;
    @include gbackground(100%);
    color: $white;
    text-shadow: none;
    @include media ("<desktop") {
      padding: 1px 2px; } }

  &__step-text-gradient {
    max-width: 450px;

    br {
      display: none; }
    span {
      display: inline-block;
      padding: 1px 5px;
      @include subtitle(1.8rem);
      line-height: 20px;
      color: $white;
      @include gbackground(450px);
      &:nth-child(1) {
        padding-top: 5px; }
      &:nth-child(8) {
        padding-bottom: 5px; } }
    @include media ("<tablet") {
      @include gbackground(100%);
      br {
        display: inline; }
      span {
        padding: 2px;
        font-size: 1.4rem;
        display: inline;
        background: none; } } }

  &__btn {
    position: relative;
    z-index: 2;
    width: 396px;
    display: flex;
    padding: 18px 29px;
    margin-left: 9px;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1.8rem;
      letter-spacing: 2px; }
    svg {
      transform: rotate(180deg); }
    &:active, &:focus {
      outline: none; }
    @include media ("<desktop") {
      width: 100%;
      margin: 0 auto;
      max-width: 500px;
      padding: 15px;
      span {
        font-size: 1.4rem; } } }

  &__btn-toast {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    color: $btn;
    top: 120%;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition;
    &--active {
      opacity: 1; } }



  &__info {
    position: relative;
    z-index: 3;
    @include container--lottery();
    margin-top: 111px;
    @include media ("<large") {
      margin-top: 70px; }
    @include media ("<desktop") {
      margin-top: 60px; }
    @include media ("<tablet");

    .landing-lottery__section-subtitle {
      margin-top: 48px; } }

  &__info-img {
    @include media ("<desktop") {
      display: block;
      img {
        max-width: 100%; } } }

  &__info-wrapper {
    display: flex;
    @include media ("<desktop") {
      flex-flow: column nowrap;
      .landing-lottery__info-img {
        display: block;
        align-self: center;
        order: 0; }
      .landing-lottery__section-subtitle {
        order: 1; } } }




  &__winner {
    position: relative;
    @include container--lottery();
    margin-top: 91px;
    z-index: 3;
    @include media ("<large") {
      margin-top: 70px; }
    @include media ("<desktop") {
      margin-top: 60px; } }

  &__winner-wrapper {
    @include resetlist();
    margin-top: 34px;
    display: flex;
    @include media ("<large") {
      margin-top: 20px; }
    @include media ("<desktop") {
      flex-flow: column nowrap;
      align-items: center; } }

  &__winner-item--1 {}

  &__winner-item--2 {
    margin-left: auto;
    .landing-lottery__winner-number {
      margin-top: -65px; }
    @include media ("<desktop") {
      margin-left: unset;
      margin-top: 30px;
      .landing-lottery__winner-number {
        order: 1;
        margin: -10px 0 0 15px; }
      .landing-lottery__winner-text-block {
        order: 0; }
      .landing-lottery__winner-text {
        text-align: right; } } }


  &__winner-item {
    display: flex;
    @include media ("<desktop") {
      flex-flow: column nowrap; } }

  &__winner-number {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: -64px;
    margin-top: -58px;
    @include subtitle(24rem);
    font-weight: 900;
    color: $white;
    text-shadow: 0 4px 45px #B195EF;
    @include media ("<desktop") {
      font-size: 12rem;
      margin: -10px 10px 0 0; } }

  &__winner-img {
    @include media ("<desktop") {
      img {
        display: block;
        margin: 0 auto; } } }


  &__winner-text-block {
    margin-left: 15px;
    @include media ("<desktop") {
      display: flex;
      margin-top: 15px; } }

  &__winner-text {
    position: relative;
    z-index: 2;
    @include subtitle(1.8rem);
    font-weight: 900;
    line-height: 116%;
    max-width: 265px;
    @include media ("<large") {
      font-size: 1.6rem; }
    @include media ("<desktop") {
      font-size: 1.4rem; }

    .landing-lottery__text-highlight {
      padding: 1px 5px; } }



  &__prizes {
    @include container--lottery();
    z-index: 3;
    margin-top: 149px;
    @include media ("<large") {
      margin-top: 90px; }
    @include media ("<desktop") {
      margin-top: 50px; } }

  &__prizes-subtitle {
    margin-top: 44px;
    @include media ("<large") {
      margin-top: 20px; }
    @include media ("<desktop") {
      margin-top: 15px; } }

  &__prizes-img-wrapper {
    display: flex;
    @include media ("<desktop") {
      flex-flow: column nowrap; } }

  &__prizes-bottle-wrapper {
    display: flex;
    margin: 74px 0 0 0;
    @include media ("<large") {
      margin-top: 50px;
      flex-basis: 40%;
      img {
        width: 140px; } }
    @include media ("<desktop") {
      margin: 30px auto 0;
      img {
        width: unset; } } }

  &__bottle-img + &__bottle-img {
    margin-left: 15px; }

  &__package-img {
    margin: 43px -79px 0 -137px;
    @include media ("<large") {
      flex-basis: 70%;
      img {
        width: 100%; } }
    @include media ("<desktop") {
      margin: 30px auto 0;
      max-width: 600px; } }


  &__participate {
    @include container--lottery();
    z-index: 3;
    margin-top: 107px;
    @include media ("<large") {
      margin-top: 70px; }
    @include media ("<desktop") {
      margin-top: 60px; } }

  &__participate-btn {
    position: relative;
    z-index: 2;
    width: 396px;
    display: flex;
    padding: 18px 29px;
    margin-top: 22px;
    align-items: center;
    justify-content: space-around;
    span {
      font-size: 1.8rem;
      letter-spacing: 2px;
      margin-left: 20px; }


    &:active, &:focus {
      outline: none; }
    @include media ("<desktop") {
      width: 100%;
      margin: 0;
      max-width: 500px;
      padding: 12px;
      span {
        font-size: 1.4rem; } } } }


