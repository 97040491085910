.documents {
  @include container--content();

  &__wrapper {
    margin-top: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    @include media("<tablet") {
      justify-content: center; } }

  &__item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 30%;
    height: 126px;
    padding: 28px 23px 21px 43px;
    margin: 9px;
    @include media("<desktop") {
      flex-basis: 46%; }
    @include media("<tablet") {
      flex-basis: 100%;
      max-width: 300px; }
    &:hover {
      .documents__item-svg {
        opacity: 1; } } }



  &__item-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    stroke: url(#linear-grad);
    pointer-events: none;
    opacity: 0.3;
    transition: opacity $transition; }

  &__item-title {
    @include subtitle();
    @include resetmargin(); }

  &__item-link {
    margin-top: auto; } }
