.test {
  padding: 30px;
  display: flex;
  flex-flow: column wrap;
  > div {
    > h2 {
      margin-top: 50px; } } }

.border-line-test {
  #border-line-2 {
    fill: transparent;
    stroke: transparent; } }
