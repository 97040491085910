.constructor__ui {
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 30px 23px 25px;
  width: 1015px;
  background-color: $white;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  @include media("<desktop") {
    width: 100%;
    padding: 15px; }
  &--active {
    z-index: 10;
    pointer-events: all;
    opacity: 1;
    transition: opacity $transition; } }

.constructor__ui-progress {
  &--active {
    .constructor__ui-text {
      opacity: 1;
      @include gbackground(100%);
      font-family: "Montserrat", sans-serif;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      border: 1px solid; } } }

.constructor__ui-title {
  @include subtitle(1.8rem);
  font-weight: 900;
  @include media("<desktop") {
    font-size: 1.4rem; } }

.constructor__ui-text {
  display: inline-block;
  min-width: 100px;
  margin-left: 10px;
  font-size: 2.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $text;
  opacity: 0.5;
  @include media("<desktop") {
    font-size: 1.8rem;
    min-width: 60px; } }

.constructor__ui-current-num {
  font-weight: 800; }


.constructor__ui-items {
  @include resetlist();
  display: flex;
  @include media("<desktop") {
    display: none; } }

.constructor__ui-item {
  position: relative;
  background-image: linear-gradient(90deg, rgba(255, 139, 222, 0.5) 0%, rgba(102, 159, 225, 0.5) 100%);
  width: 37px;
  height: 37px;
  margin: 0 5px;
  border-radius: 50%;
  overflow: hidden;
  img {
    position: relative;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2; }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 18px;
    width: 2px;
    background-color: $white;
    z-index: 1; }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 2px;
    width: 18px;
    background-color: $white;
    z-index: 1; }

  &--full {
    img {
      opacity: 1; }
    &::after, &::before {
      display: none; } } }


.constructor__ui-next-btn {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 36px;
  @include subtitle(1.6rem);
  font-weight: 900;
  background-color: transparent;
  border: none;
  border-left: 1px solid $text;
  opacity: 0.5;
  pointer-events: none;
  cursor: pointer;
  transition: opacity $transition, color $transition;
  &:active, &:focus {
    outline: none; }
  @include media("<desktop") {
    padding: 3px 7px 3px 16px;
    font-size: 1.2rem;
    border: none; }

  svg {
    position: relative;
    left: 0;
    margin-left: 15px;
    fill: $text;
    transition: left $transition; }
  &--active {
    color: $btn;
    pointer-events: all;
    opacity: 1;
    svg {
      fill: $btn; }
    &:hover {
      svg {
        left: 10px; } }
    &:active, &:focus {
      outline: none; } } }
