html {
  font-size: 62.5%;
  * {
    box-sizing: border-box; } }

body {
  @include resetmargin();
  position: relative;
  font-family: "Montserrat", sans-serif;
  background-color: $bg-color; }

.btn {
  @include resetinteractive;
  display: inline-block;
  padding: 12px 10px;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: $white;
  background-color: $btn;
  border: 2px solid $btn;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  transition: background-color $transition, color $transition, outline $transition;
  &:hover {
    color: $btn;
    background-color: $white;
    .btn-arrow {
      fill: $btn; } }
  &--disabled {
    background-color: lighten($text, 50%);
    border-color: lighten($text, 50%);
    pointer-events: none; }
  &--gr {
    padding: 4px 10px;
    border: none;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-image: linear-gradient(90deg, #FF8BDE 50%, #669FFF 100%);
    transition: background-position $transition;
    &:hover {
      background-position: 0 0;
      color: $white; } }
  .btn-arrow {
    fill: $white;
    transition: fill $transition, transform $transition; } }

.link-btn {
  font-size: 1.6rem;
  display: inline-block;
  text-decoration: underline;
  color: $btn;
  font-weight: 600;
  border: none;
  background-color: transparent; }

.title-gradient {
  @include gbackground(100%, 0%);
  @include resetmargin;
  display: inline-block;
  padding: 0 22px;
  font-family: "Montserrat", sans-serif;
  font-size: 7.2rem;
  font-weight: 900;
  text-transform: uppercase;
  color: $white;
  @include media("<huge") {
    font-size: 6.0rem; }
  @include media("<large") {
    font-size: 4.8rem; }
  @include media("<desktop") {
    font-size: 3.6rem; }
  @include media("<tablet") {
    font-size: 2.8rem; } }


.main {
  &--padded {
    padding-top: 140px;
    @include media("<large") {
      padding-top: 150px; }
    @include media("<tablet") {
      padding-top: 90px; } }
  &--cart {
    padding-top: 95px;
    @include media("<huge") {
      padding-top: 150px; }
    @include media("<tablet") {
      padding-top: 90px; } }
  &--landing-1 {
    background-image: url("../img/splash-bg-3.jpg"), url("../img/splash-bg-5.jpg");
    background-repeat: no-repeat;
    background-position: right top, left bottom;
    text-transform: uppercase;
    padding-bottom: 30px;
    padding-top: 100px;
    @include media ("<tablet") {
      padding-bottom: 60px; }
    @include media ("retina2x") {
      background-image: url("../img/splash-bg-3@2x.jpg"), url("../img/splash-bg-5@2x.jpg"); // both need to get tiny
      background-size: 1097px, 100%;
      @include media ("<tablet") {
        background-size: 250%, contain; } } }
  &--landing-2 {
    background-image: url("../img/splash-bg-3.jpg"), url("../img/splash-bg-5.jpg"); // both need to get tiny
    background-repeat: no-repeat;
    background-position: right top, left bottom;
    background-size: auto, contain;
    background-color: $bg-color; // won't work while I have jpg's on bg
    overflow: hidden;
    @include media ("<tablet") {
      padding-top: 75px; }
    @include media ("retina2x") {
      background-image: url("../img/splash-bg-3@2x.jpg"), url("../img/splash-bg-5@2x.jpg"); // both need to get tiny
      background-size: 1097px, 100%;
      @include media ("<tablet") {
        background-size: 250%, contain; } }
    @include media ("<tablet") {
      background-image: url("../img/splash-bg-3@2x.jpg");
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 700px; } }

  &--landing-3 {
    background-color: $bg-color;
    overflow: hidden;
    padding-top: 0; }

  &--landing-lottery {
    padding-top: 95px;
    padding-bottom: 100px;
    overflow: hidden;
    @include media("<huge") {
      padding-top: 120px; }
    @include media("<tablet") {
      padding-top: 70px;
      background-image: url("../img/landing-lottery__bg.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 700px; }
    @include media ("retina2x") {
      background-image: url("../img/landing-lottery__bg@2x.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 1440px; } }





  &--constructor {
    overflow: hidden;
    padding-top: 93px;
    min-height: 100vh;
    background-image: url("../img/constructor-landing-bg.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    @include media ("retina2x") {
      background-image: url("../img/constructor-landing-bg@2x.jpg");
      background-size: 1088px; }
    @include media ("<huge") {
      padding-top: 140px; }
    @include media ("<tablet") {
      padding-top: 82px;
      background-image: url("../img/constructor-landing-bg-sm.jpg");
      @include media ("retina2x") {
        background-image: url("../img/constructor-landing-bg-sm@2x.jpg");
        background-size: 723px; } } }

  &-contact-us {
    background-image: url("../img/map.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
    height: 100vh;
    padding-top: 118px;
    // @media screen and (max-width: 680px)
    //   background-size: contain
    //   padding-bottom: 300px
    // @media screen and ( max-width: 560px )
    @include media ("<desktop") {
      // background-size: contain
      height: auto;
      background-size: 135%;
      padding-bottom: 300px; }
    // @include media ("<tiny")
 } }    //   background-size: 135%

.main {
  &--submenu {
    padding-top: 250px;
    @include media("<tablet") {
      padding-top: 160px; } } }


.custom-checkbox {
  position: relative;
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 560px) {
    font-size: 1.0rem; }
  &__text {
    padding-left: 15px;
    user-select: none; }
  &__checkmark {
    position: absolute;
    fill: $white;
    left: 0;
    width: 22px;
    height: 22px;
    opacity: 0;
    transition: opacity $transition; }
  &__input {
    appearance: none;
    cursor: pointer;
    position: relative;
    height: 22px;
    width: 22px;
    padding: 0;
    background: #A9A9A9;
    margin: 0;
    transition: outline $transition;
    border-radius: 0;
    flex-shrink: 0;
    &:checked {
      ~ svg {
        opacity: 1; } } } }


.icon-close {
  fill: url(#linear-grad);
  transition: fill $transition;
  &:hover {
    fill: url(#linear-grad-2); }
  @include media ("<tablet") {
    fill: #231F20;
    height: 16px;
    width: 16px; } }

.svg-gradient-line {
  stroke: url(#linear-grad); }

.svg-gradient-arrow {
  stroke: url(#linear-grad);
  fill: url(#linear-grad-3); }

.svg-circle-close {
  stroke: url(#linear-grad);
  fill: none;
  cursor: pointer;
  &:hover {
    stroke: url(#linear-grad-3); } }

// might go into landing.sass
.svg-grad-big-arrow {
  fill: url(#linear-grad-4);
  position: absolute;
  @include media ("<large") {
    display: none; } }

.svg-promo { // maybe just put this on landing through nesting, doesn't need a class
  fill: url(#linear-grad-4); }

.wpcf7 .screen-reader-response,
.wpcf7 .wpcf7-validation-errors {
  display: none; }

form .wpcf7-not-valid {
  outline: 1px solid #D62267;
  color: #D62267;
  form .wpcf7-not-valid::-webkit-input-placeholder {
    color: #D62267; }
  form .wpcf7-not-valid::-moz-placeholder {
    color: #D62267; }
  form .wpcf7-not-valid:-ms-input-placeholder {
    color: #D62267; }
  form .wpcf7-not-valid::-ms-input-placeholder {
    color: #D62267; }
  form .wpcf7-not-valid::placeholder {
    color: #D62267; } }
.wpcf7 .wpcf7-not-valid-tip {
  color: #D62267; }

input[type='text'], input[type='tel'], [type='email'], [type='submit'] {
  border-radius: 0;
  &:active, &:focus {
    outline: none; } }

input[type='checkbox'] {
  &:active, &:focus {
    outline: 1px solid #A9A9A9; } }
