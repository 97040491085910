.catalog--constructor {
  min-height: unset;
  z-index: 3;
  position: relative;

  @include media("<tablet") {
    min-height: 500px; }



  .catalog__wrapper {
    margin: 0;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    &--active {
      margin-top: 50px;
      padding-bottom: 100px;
      opacity: 1;
      max-height: unset;
      min-height: 1000px;
      align-content: flex-start;
      @include media("<large") {
        margin-top: 30px; } } }

  .catalog__item {
    &:hover {
      .catalog__item-border {
        opacity: 0.7; } }
    .catalog__item-border {
      opacity: 0.3; } }

  .catalog__price-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around; }

  .catalog__item--selected {
    &:hover {
      .catalog__item-border {
        opacity: 1; } }
    .catalog__item-border {
      opacity: 1; }
    .catalog__item-link--remove {
      position: static;
      opacity: 1;
      max-width: unset;
      overflow: hidden; } }

  .catalog__item-link {
    margin-left: unset;
    max-height: 18px;
    &--remove {
      position: absolute;
      color: $main-link;
      opacity: 0;
      max-width: 0;
      overflow: hidden; } } }

