.constructor__index {
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  * {
    pointer-events: none; }
  @include media("<huge") {
    justify-content: space-around; }
  @include media("<tablet") {
    margin-top: 10px;
    height: 500px; }
  &--active {
    pointer-events: all;
    opacity: 1;
    max-height: unset;
    overflow: visible;
    padding-bottom: 20px;
    * {
      pointer-events: all; } } }

#constructor-anchor {
  position: absolute;
  top: 85vh;
  width: 10px;
  height: 10px;
  pointer-events: none; }


.constructor__box {
  position: relative;
  min-height: 485px;
  overflow: visible;
  z-index: 1;
  margin-bottom: -10px;
  margin-top: -30px;
  transition: all $transition;
  &:hover {
    z-index: -1;
    .constructor__splash-img {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(0deg);
      transition: transform $transition*2; }
    .constructor__blob-btn {
      opacity: 1;
      transition: opacity $transition*2; } }
  &--1 {
    flex-basis: 40%; }
  &--2 {
    flex-basis: 60%; }
  @include media("<huge") {
    min-height: unset;
    height: 300px;
    &--1 {
      flex-basis: 30%; }
    &--2 {
      flex-basis: 40%; } }
  @include media("<tablet") {
    position: absolute;
    &:hover {
      .constructor__splash-img, .constructor__blob-btn {
        opacity: 0;
        display: none; } } } }



.js-box-1-mobile-active {
  @include media("<tablet") {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%); } }


.js-box-1-mobile-inactive {
  @include media("<tablet") {
    opacity: 0.7;
    transform: scale(0.5);
    left: 0; } }

.js-box-2-mobile-active {
  @include media("<tablet") {
    right: 50%;
    transform: translateX(-50%); } }

.js-box-2-mobile-inactive {
  @include media("<tablet") {
    opacity: 0.7;
    transform: scale(0.5);
    right: 0; } }


.constructor__blob-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  cursor: pointer;
  svg {
    position: absolute;
    left: 40%;
    top: 40%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    fill: url(#linear-grad-3); }
  span {
    position: absolute;
    left: 40%;
    top: 40%;
    transform: translate(-50%, -50%);
    @include subtitle(1.8rem);
    color: $white;
    font-weight: 800; }
  @include media("<tablet") {
    pointer-events: none;
    display: none; }


  &--1 {
    left: 45%; }
  &--2 {
    left: 55%; } }

.constructor__box-img {
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &--1 {
    max-width: 330px; }
  &--2 {
    max-width: 480px;
    left: 58%;
    top: 45%; }
  @include media("<huge") {
    width: auto;
    &--1 {
      height: 100%; }
    &--2 {
      height: 130%; } }
  @include media("<tablet") {
    &--1 {
      height: 158px; }
    &--2 {
      height: 180px; } } }


.constructor__splash-img {
  z-index: 1;
  position: absolute;
  width: 180%;
  left: 60%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.3) rotate(90deg); }

.constructor__box-mobile-selector {
  display: none;
  @include media("<tablet") {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    margin-bottom: 20px;
    max-height: 50px;
    &-span {
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $text;
      opacity: 0.7;
      cursor: pointer;
      transition: opacity $transition;
      &--active {
        font-weight: 800;
        opacity: 1;
        text-decoration: underline; } }
    &-span + &-span {
      margin-left: 15px; } } }



.constructor__box-title-block {
  display: flex;
  align-items: center; }

.constructor__box-price-tag {
  @include gbackground(500px);
  padding: 5px 15px;
  margin-left: 25px;
  .price {
    font-size: 2.4rem; }
  .price + .price {
    margin-left: 25px; }
  @include media("<tablet") {
    margin-left: 0;
    .price {
      font-size: 20px; }
    .price + .price {
      margin-left: 10px; } } }


.constructor__index-desktop-title {
  @include subtitle(2.4rem);
  font-weight: 900;
  margin: 0;
  @include media("<huge") {
    font-size: 1.8rem; }
  @include media("<tablet") {
    display: none; } }

.constructor__box-info {
  z-index: 5;
  @include media("<huge") {
    flex-basis: 50%; }
  @include media("<tablet") {
    flex-basis: 100%;
    height: 240px;
    width: 95%;
    z-index: 5;
    position: absolute;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0; } }

.js-info-mobile-active {
  opacity: 1; }

.constructor__box-info--2 {
  margin-left: auto;
  @include media("<huge") {
    margin-left: unset; } }




.constructor__box-text {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 21px;
  span {
    font-size: 2.4rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: $text;
    margin-right: 10px; }
  @include media("<huge") {
    margin-top: 15px;
    span {
      font-size: 1.8rem; } }
  @include media("<large") {
    span {
      flex-basis: 100%;
      margin-bottom: 10px; } }
  @include media("<tablet") {
    margin-top: 8px;
    span {
      font-size: 1.6rem; } } }

.constructor__box-text--aromas {
  @include media("<tablet") {
    img {
      margin-left: -4px; } } }

.constructor__box-text--box {
  display: flex;
  flex-flow: row nowrap;
  width: max-content;
  margin-top: 23px;
  @include gbackground(100%);
  padding: 3px 0 6px 10px;
  span:nth-child(1) {
    font-weight: 900;
    margin-left: 20px; }
  span:nth-child(2) {
    margin-left: 50px;
    margin-right: 50px; }
  span {
    display: block;
    color: $white;
    font-weight: 500;
    line-height: 2.4rem; }
  img {
    margin: -15px -30px -20px 0;
    height: 80px; }
  @include media("<huge") {
    margin-top: 17px;
    padding: 0 0 3px 5px;
    img {
      height: 46px;
      margin-right: -20px; }
    span:nth-child(1) {
      margin-bottom: 0; }
    span:nth-child(2) {
      min-width: 120px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0; } }
  @include media("<tablet") {
    padding: 5px;
    margin-top: 8px;
    span:nth-child(2) {
      line-height: 100%;
      margin-left: 20px;
      margin-right: 20px; } } }

.constructor__bonus {
  display: flex;
  align-items: center;
  margin-left: 15px;
  @include gbackground(100%);
  padding: 0 10px 3px 10px;
  span {
    font-weight: 600;
    color: $white;
    margin-bottom: 0; }
  img {
    margin: -20px 0;
    max-height: 73px; }
  @include media("<huge") {
    max-width: max-content;
    padding: 0 0 3px 5px;
    margin-bottom: 0 !important;
    img {
      margin: -20px 0;
      max-height: 53px; }
    .constructor__mobile-hidden {
      display: none; } }
  @include media("<large") {
    //position: absolute
    //right: 100px
    //span
 }    //  margin: 0
  @include media("<tablet") {
    margin-left: 5px;
    display: inline-block;
    position: static; } }

.constructor__index-mobile-btn {
  display: none;
  @include media("<tablet") {
    display: block;
    position: absolute;
    bottom: -2px;
    left: -15px;
    width: calc(100% + 30px); } }
