.landingmb {
  display: none;
  @include media("<tablet") {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 10;
    opacity: 1;
    pointer-events: all;
    transition: $transition;
    &:hover {
      background-color: $btn;
      color: $white; }
    &--red {
      background-color: red; } }

  &__main-text {
    font-size: 1.2rem;
    letter-spacing: 0.4rem;
    font-weight: 900;
    max-width: 85%; }
  &__sub-text {
    font-weight: 400;
    letter-spacing: 0.1rem; }

  &__arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    fill: $white; }

  &--hidden {
    opacity: 0;
    pointer-events: none; } }
