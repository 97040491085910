.gallery {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex: 0 0;

  &__img-wrap {
    @include resetmargin();
    height: 92px;
    width: 92px;
    cursor: pointer;
    transform: scale(1);
    transition: transform $transition;
    &:hover {
      transform: scale(1.1); }
    &--main {
      margin-left: 55px;
      height: 339px;
      width: 339px;
      &:hover {
        transform: none; } } }

  &__img-wrap + &__img-wrap {
    margin-top: 30px; }

  &__img-wrap + &__img-wrap--main {
    margin-top: 0; }

  &__img {
    width: 100%;
    object-fit: contain; } }
