@mixin resetlist {
  padding-left: 0;
  margin: 0;
  list-style: none; }

@mixin resetmargin {
  margin: 0;
  padding: 0; }

@mixin resetinteractive {
  text-decoration: none;
  background: none;
  border: none; }

@mixin container {
  box-sizing: border-box;
  width: 1440px;
  margin: 0 auto 0;
  @include media("<huge") {
    width: 1280px;
    margin: 0 auto 0;
    padding: 0 15px; }
  @include media("<large") {
    width: 1024px;
    margin: 0 auto 0;
    padding: 0 15px; }
  @include media("<desktop") {
    width: 100%; } }

@mixin container--content {
  box-sizing: border-box;
  width: 1440px;
  margin: 0 auto 0;
  padding: 0 132px 0;
  @include media("<huge") {
    width: 1280px;
    margin: 0 auto 0;
    padding: 0 15px; }
  @include media("<large") {
    width: 1024px;
    margin: 0 auto 0;
    padding: 0 15px; }
  @include media("<desktop") {
    width: 100%; } }

@mixin container--lottery {
  box-sizing: border-box;
  width: 1440px;
  margin: 0 auto 0;
  padding: 0 151px 0;
  @include media("<huge") {
    width: 1280px;

    padding: 0 15px; }
  @include media("<large") {
    width: 1024px;

    padding: 0 15px; }
  @include media("<desktop") {
    width: 100%; } }

@mixin gbackground($width: 100%, $start: 0%) {
  background-size: $width 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, $gradient-start $start, $gradient-end 100%); }

@mixin subtitle($font-size: 1.8rem) {
  font-family: "Montserrat", sans-serif;
  font-size: $font-size;
  color: $text;
  text-transform: uppercase; }

@mixin mobilemaw($mwidth: 320px) {
  margin: 0 auto 0;
  width: 100%;
  max-width: $mwidth; }

@mixin price-after() {
  &::after {
    text-transform: lowercase;
    content: " p."; } }
