.catalog-form {

  &__wrapper {
    display: flex;
    //margin-top: 75px
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center; }

  &__img {
    margin-right: -15px;
    margin-top: 30px;
    width: 60%;
    @include media ("<large") {
      max-width: 62%; }
    @include media ("<desktop") {
      max-width: 100%; }
    @include media ("<tablet") {
      margin: 0 auto; } } // check if also applicable to other sizes (with pixel perfect)

  &__form {
    display: flex;
    flex-flow: column;
    @include media ("<desktop") {
      width: 100%;
      max-width: 500px; }

    .wpcf7-form-control-wrap {
      display: flex;
      flex-flow: row wrap;
      max-width: max-content;
      .catalog-form__input {
        margin-top: 30px;
        margin-bottom: 0; }
      .wpcf7-not-valid-tip {
        flex-basis: 100%;
        margin-top: 10px; } } }

  &__input {
    @include gbackground (100%);
    opacity: 0.5;
    width: 373px;
    padding: 15px 10px;
    border: none;
    margin-top: 30px;
    color: $white;
    font-weight: 800;
    appearance: none;
    border-radius: 0;
    @include media ("<desktop") {
      width: 100%;
      opacity: 0.7; }
    @include media ("<tablet") {
      background: $white;
      opacity: 1;
      color: $text;
      font-size: 1.4rem; }
    &::placeholder {
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      @include media ("<tablet") {
        color: $text;
        opacity: 0.5; } }
    &:focus {
      opacity: 0.9; }
    &--error {
      outline: 1px solid $main-link;
      color: $main-link;
      &::placeholder {
        color: $main-link; } } }

  &__btn {
    width: 373px;
    padding: 15px 10px;
    margin-top: 20px;
    border: 2px solid $btn;
    transition: background-color $transition, color $transition, outline $transition;
    appearance: none;
    @include media ("<desktop") {
      width: 100%; } } }
