.index {
  height: 100vh;
  @include media("<tablet") {
    height: calc((var(--vh, 1vh) * 100) - 54px);
    overflow: hidden; }

  &__static {
    @include container--content();
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    height: 100%;
    padding-top: 172px;
    padding-bottom: 52px;
    z-index: 2;
    @include media("<huge") {
      padding-top: 172px;
      padding-bottom: 52px; }
    @include media("<tablet") {
      padding-top: 65px;
      height: calc((var(--vh, 1vh) * 100) - 54px); }
    @media screen and (max-height: 750px) and (min-width: 1440px) {
      padding-top: 140px; } }

  &__hash-title {
    @include subtitle(7.2rem);
    @include gbackground(100%, 0%);
    @include resetmargin();
    display: inline-block;
    margin-bottom: 27px;
    padding: 0 17px;
    font-weight: 900;
    color: $white;
    @include media("<desktop") {
      font-size: 5rem; }
    @include media("<tablet") {
      font-size: 3rem; }
    @media screen and (max-height: 750px) {
      margin-bottom: 5px; } }

  &__main-title {
    @include subtitle(1.8rem);
    line-height: 3.0rem;
    text-transform: uppercase;
    font-weight: 900;
    max-width: 365px;
    @include media("<desktop") {
      font-size: 1.6rem; }
    @include media("<tablet") {
      max-width: 70%;
      font-size: 1.2rem;
      line-height: 2rem; }
    @media screen and (max-height: 750px) and (min-width: 1440px) {
      margin-bottom: 5px; } }

  &__video-block {
    position: relative;
    justify-content: center;
    margin: auto 0 20px;
    padding: 15px 0 0;
    @include media("<huge") {
      padding-top: 0;
      margin-top: 10px; }
    @media screen and (max-height: 750px) and (min-width: 1440px) {
      margin-top: 5px; } }

  &__video-mask {
    opacity: 0;
    z-index: -100;
    position: absolute; }

  &__video-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 340px;
    width: 350px;
    background-image: url("../img/index-vid-bg.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 120%;
    clip-path: url(#my-clip);
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1; }
    @include media("<huge") {
      height: 180px;
      width: 200px; }
    @media screen and (max-height: 820px) and (min-width: 1440px) {
      height: 240px;
      width: 260px; }
    @include media("<tablet") {
      display: none; } }




  &__video-play-btn {
    @include subtitle(2.8rem);
    font-weight: 900;
    z-index: 2;
    fill: $white;
    cursor: pointer;
    //transition: transform $transition
    //transform: scale(1)
    color: $white;
    text-shadow: 0 0 3px $black;
    text-align: center;
    //&:hover
 }    //transform: scale(1.05)

  &__video-mobile-btn {
    display: none;
    @include media("<tablet") {
      position: fixed;
      bottom: 0;
      left: 0;
      appearance: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: 100%;
      z-index: 10;
      @include subtitle(1.2rem);
      letter-spacing: 2px;
      @include gbackground (100%);
      color: $white;
      padding: 3px 7px;
      text-transform: uppercase;
      font-weight: 600; } }





  //&__video-svg
  //  position: absolute
  //  top: 0
  //  left: 0
  //  z-index: 2
  //
  //&__video-btn
  //  border: none
  //  background: none
  //  span
  //    @include gbackground(200%, 50%)
  //    position: relative
  //    display: inline
  //    font-size: 1.8rem
  //    font-weight: 800
  //    letter-spacing: 1px
  //    text-transform: uppercase
  //    color: transparent
  //
  //    background-position: 100% 0
  //    background-clip: text
  //    -webkit-background-clip: text
  //    cursor: pointer
  //    transition: background-position $transition
  //    z-index: 3
  //  &:hover
  //    span
  //      background-position: 0 0
  //  &:active, &:focus
  //    outline: none

  &__video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
    width: 100%;
    opacity: 0;
    transition: opacity $transition;
    pointer-events: none;
    iframe {
      width: 100%;
      height: 85vh;
      pointer-events: none;
      @include media("<tablet") {
        height: 70vh; } }
    &--active {
      opacity: 1;
      pointer-events: all;
      iframe {
        pointer-events: all; }
      ~ .index__video-backdrop {
        opacity: 0.7;
        pointer-events: all; } } }

  &__video-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    opacity: 0;
    background-color: $black;
    transition: opacity $transition;
    pointer-events: none; }

  &__video-close {
    background: none;
    border: none;
    appearance: none;
    position: absolute;
    top: -38px;
    right: 10px;
    svg {
      height: 30px;
      width: 30px;
      fill: url(#linear-grad); }
    &:focus {
      outline: 0;
      svg {
        stroke: url(#linear-grad-2); } }
    &:active {
      svg {
        transform: translateY(-2px); } } }




  &__all-products-block {
    width: 100%;
    display: flex;
    margin-top: auto;
    align-items: center;
    @include media("<tablet") {
      margin-bottom: 0; } }


  &__all-products-btn {
    position: relative;
    @include subtitle(1.4rem);
    font-weight: 600;
    padding: 7px 10px;
    color: $white;
    br {
      display: none; }
    @include media("<tablet") {
      color: $main-link;
      background: none;
      padding-bottom: 15px;
      &:hover {
        background: none;
        color: $main-link-lighter; }
      br {
        display: inline; } } }


  &__all-products-arrow {
    display: none;
    @include media("<tablet") {
      position: absolute;
      bottom: 0;
      display: block;
      fill: $main-link; } }


  &__progress-bar {
    position: relative;
    margin-right: 16px;
    flex-grow: 1;
    height: 3px;
    background-color: lighten($text, 65%);
    @include media("<tablet") {
      display: none; } }

  &__progress-bar-filler {
    @include gbackground(100%, 0%);
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    &--animate {
      animation-duration: 5s;
      animation-name: progress-bar;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; } }

  &__name-block {
    top: 50%;
    position: absolute;
    left: 650px;

    @include media("<huge") {
      top: unset;
      left: 15px;
      bottom: 86px; }
    @include media("<tablet") {
      position: static; }

    @media screen and (max-height: 820px) and (max-width: 1439px) {
      left: 350px;
      top: 55vh; }

    @media screen and (max-height: 820px) and (min-width: 1440px) {
      left: 450px;
      top: 55vh; } }




  &__product-type {
    display: flex;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 400;
    span {
      opacity: 0;
      transform: translate(0, -10px);
      display: block;
      min-width: 15px; }
    @include media("<tablet") {
      font-size: 1.2rem; } }

  &__product-name {
    display: flex;
    font-size: 3.6rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 100%;
    color: $black;
    span {
      opacity: 0;
      transform: translate(0, -10px);
      display: block;
      min-width: 15px;
      text-align: center; }
    @include media("<tablet") {
      font-size: 1.8rem;
      span {
        min-width: 8px; } } }


  &__more-info-btn {
    display: block;
    margin-top: 40px;
    text-decoration: none;
    &:hover {
      .index__more-info-text {
        color: $main-link-lighter; }
      .index__more-info-arrow {
        transform: translateX(50px);
        fill: $main-link-lighter; } }
    @include media("<tablet") {
      display: none; } }

  &__more-info-text {
    @include subtitle(1.8rem);
    font-weight: bold;
    display: block;
    color: $main-link;
    transition: color $transition; }


  &__more-info-arrow {
    fill: $main-link;
    transform: translateX(0);
    transition: transform $transition, fill $transition; }

  &__slider {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    //border: 2px solid red
    width: 966px;
    z-index: 1;
    @include media("<tablet") {
      width: 100%;
      height: 90vh; } } }



@keyframes progress-bar {
  from {
    width: 0; }
  to {
    width: 100%; } }
