.cart-modal {
  background-image: url('../img/cbm-bg.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding: 30px;

  &__brand {
    font-size: 1.8rem;
    font-weight: 800;
    color: $black;
    text-align: center;
    display: flex;
    align-items: flex-start;
    @include media ("<tablet") {
      margin: 0; } }

  &-form {

    &__wrapper {
      max-width: 454px;
      width: 95%;
      color: $text;
      position: absolute;
      top: 30%;
      @include media ("<tiny") {
        max-width: 276px; } }

    &__title {
      font-size: 3.0rem;
      text-transform: uppercase;
      margin: 0;
      @include media ("<tiny") {
        color: $white;
        font-size: 2.2rem; } }

    &__subtitle {
      font-size: 2.4rem;
      text-transform: uppercase;
      margin: 0 0 30px;
      @include media ("<tiny") {
        color: $white;
        font-size: 2rem; } }

    &__form {
      width: 100%;

      label:not(.cbmodal-form__cbx-label) {
        display: none; } }

    &__input-wrap {
      margin-bottom: 15px;
      // background-clip: text
      // background-color: $white
      .btn {
        width: 100%;
        padding: 15px; } }

    &__input {
      width: 100%;
      @include gbackground (100%);
      color: $white;
      outline-color: $gradient-end;
      opacity: 0.7;
      padding: 15px 10px;
      border: none;
      font-weight: 600;
      font-size: 1.8rem;
      @include media ("<tiny") {
        background: $white;
        opacity: 1;
        color: $text; }
      &::placeholder {
        color: $white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.4rem;
        @include media ("<tiny") {
          color: $text;
          opacity: 0.5; } }
      &--error {
        outline: 1px solid $main-link;
        color: $main-link;
        &::placeholder {
          color: $main-link; } } }

    // input
    //   &:not(.cbmodal-form__form-cbx-input)
    //     width: 100%
    //     &:not(.cbmodal-form__submit-btn)
    //       @include gbackground (100%)
    //       color: $white
    //       opacity: 0.7
    //       padding: 15px 10px
    //       border: none
    //       font-weight: 600
    //       font-size: 1.8rem
    //       @include media ("<tiny")
    //         background: $white
    //         opacity: 1
    //         color: $text
    //       &::placeholder
    //         color: $white
    //         text-transform: uppercase
    //         font-weight: 600
    //         font-size: 1.4rem
    //         @include media ("<tiny")
    //           color: $text
    //           opacity: 0.5

    &__input-wrap--cbx {
      margin: 35px 0;
      font-size: 1.2rem;
      @include media ("<tiny") {
        display: none; } }

    &__btn-wrap {
      font-size: 1.4rem;
      @include media ("<tiny") {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        letter-spacing: 0.2rem; } } }

  // &-btn
  //   padding: 15px


  &-thanks {
    &__wrapper {
      position: absolute;
      top: 30%;
      text-transform: uppercase;
      width: 640px;
      @include media ("<tablet") {
        width: 265px; } }

    &__title {
      span {
        @include gbackground(640px);
        color: $white;
        font-size: 7.2rem;
        font-weight: 800;
        margin: 0;
        padding: 0 20px;
        @include media ("<tablet") {
          @include gbackground(265px);
          font-size: 3rem;
          padding: 0 10px; } } }

    &__subtitle {
      color: $text;
      font-size: 1.8rem;
      font-weight: 600;
      @include media ("<tablet") {
        font-size: 1.4rem;
        color: $white; } } } }

.modal-wrapper {
  opacity: 0;
  pointer-events: none;
  * {
    pointer-events: none; }
  &--active {
    opacity: 1;
    pointer-events: all;
    * {
      pointer-events: all; } } }

.cart-modal-form__submit-btn {
  appearance: none; }
