.contact-us {
  @include container--content;
  color: $text;

  &__info-form--wrapper {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.8rem; }

  &__title {
    @include gbackground(100%);
    margin-top: 0;
    margin-bottom: 20px;
    display: inline-block;
    color: $white;
    font-size: 7.2rem;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0px 20px;
    @include media ("<tablet") {
      font-size: 3.0rem;
      padding: 10px;
      font-weight: 800;
      margin-bottom: 50px; } }

  &__contact {
    @include resetinteractive;
    display: block;
    color: $text;
    line-height: 2.3rem; }

  &__address-subtitle {
    @include subtitle(1.8rem);
    font-weight: 800;
    margin: 20px 0 15px;
    @include media ("<tablet") {
      font-size: 1.8rem; } }

  &__phone-email-subtitle {
    @include subtitle(1.8rem);
    font-weight: 800;
    margin: 20px 0 15px;
    @include media ("<tablet") {
      max-height: 0;
      padding: 0;
      overflow: hidden;
      margin: 0; } }

  &__address {
    flex-basis: 50%;
    width: 50%;
    @include media ("<tablet") {
      order: 1;
      flex-basis: 100%;
      margin-top: 70px;
      font-size: 1.6rem; } }

  &__phone-email {
    flex-basis: 50%;
    @include media ("<desktop") {
      margin-top: 60px;
      flex-basis: 100%; }
    @include media ("<tablet") {
      margin: 0; } }

  &__phone-email--wrapper {
    display: flex;
    @include media ("<desktop") {
      display: block; }
    @include media ("<tablet") {
      text-decoration: underline;
      font-size: 1.6rem; } }

  &__phone-nrs {
    margin-right: 100px;
    @include media ("<large") {
      margin-right: 40px; }
    @include media ("<tablet") {
      margin: 0;
      a {
        margin-bottom: 30px; } } }

  &__form--wrapper {
    flex-basis: 1;
    max-width: 540px;
    width: 100%;
    margin-top: 60px;
    @include media ("<tablet") {
      margin-top: 50px;
      padding: 0; } }

  &__checkbox-wrap {
    display: flex;
    align-items: flex-end;
    a {
      margin-left: 15px;
      color: $text; } }


  &__form-motto {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 3.0rem;
    font-weight: 900;
    background: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    background-clip: text;
    color: transparent;
    margin-bottom: 0;

    @include media ("<tablet") {
      font-size: 2.4rem;
      margin-top: 0; } }

  &__after-form-motto {
    font-size: 2.4rem;
    margin: 0 0 15px;
    @include media ("<tablet") {
      font-size: 1.8rem; } }

  .form {
    font-size: 1.4rem;
    max-width: 454px;

    &__input {
      font-size: 1.8rem;
      margin-top: 15px;
      max-width: 100%;
      padding: 11px 10px;
      &::placeholder {
        font-size: 1.4rem; } }

    &__btn {
      @include media ("<tiny") {
        padding: 10px 0;
        order: 1;
        margin-top: 50px; } }

    &__cbx-label {
      font-size: 1.2rem;
      margin-top: 15px;
      @include media ("<tablet") {
        font-size: 1.0rem; }
      @include media ("<tiny") {
        margin-top: 15px; } } } }

