.catalog {
  @include container--content();
  min-height: 100vh;

  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    > div {
      &:nth-child(odd) {
        transform: translate(0, -5px); }
      &:nth-child(even) {
        transform: translate(0, 5px); } }
    @include media("<huge") {
      justify-content: center;
      > div {
        &:nth-child(odd) {
          transform: translate(0, 0); }
        &:nth-child(even) {
          transform: translate(0, 0); } } } }


  &__item {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    width: 273px;
    height: 305px;
    opacity: 0;
    * {
      z-index: 2; }
    &:hover {
      .catalog__item-border {
        opacity: 1; } }
    &--active {
      opacity: 1;
      transition: opacity $transition;
      padding: 20px 30px;
      margin: 10px 10px;
      overflow: visible;
      max-height: unset;
      max-width: unset; } }

  &__item-border {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    opacity: 0.3;
    transition: opacity $transition; }

  &__item-code {
    position: absolute;
    top: 23px;
    left: 20px;
    font-size: 1.2rem;
    text-transform: uppercase;
    z-index: 3; }

  &__item-img {
    z-index: 1;
    width: 100%;
    object-fit: contain;
    margin-top: auto;
    max-height: 190px;
    margin-bottom: -10px;
    opacity: 1;
    transition: opacity $transition;
    &--hidden {
      opacity: 0; } }

  &__item-toast {
    position: absolute;
    top: 30%;
    @include subtitle(2.0rem);
    font-weight: 900;
    text-align: center;
    color: transparent;
    letter-spacing: 2px;
    @include gbackground(100%);
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 100% 0;
    opacity: 0;
    transition: opacity $transition;
    &--active {
      opacity: 1; } }

  &__price-block {
    position: relative;
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    padding-top: 35px;
    margin-top: auto;
    margin-bottom: 10px;
    @include media("<desktop") {
      padding-top: 26px; } }

  &__item-name {
    @include resetmargin;
    @include subtitle(2rem);
    font-weight: 900;
    position: absolute;
    text-decoration: none;
    bottom: 22px;
    width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
    text-shadow: 0 0 3px $bg-color, 0 0 3px $bg-color;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: -200px; }
    @include media("<desktop") {
      font-size: 1.6rem; } }

  &__item-price {
    font-size: 1.8rem;
    &:after {
      content: " p."; } }

  &__item-link {
    position: relative;
    margin-left: auto;
    transition: color $transition;
    &:hover {
      color: darken($btn, 10%); }
    &:active {
      color: darken($btn, 15%);
      top: -1px;
      transition: none; } } }













