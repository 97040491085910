.landing-1 {

  section {
    @include container--content;
    &:nth-of-type(1), &:nth-of-type(5) {
      overflow: hidden; } }

  section + section {
    margin-top: 150px;
    @include media ("<tablet") {
      margin-top: 100px; } }

  // 1ST SECTION
  &__main-title {
    color: $white;
    font-size: 4.8rem;
    line-height: 5.5rem;
    font-weight: 900;
    text-transform: uppercase;
    @include media ("<large") {
      font-size: 3.5rem; // 3.6rem
      line-height: 4rem; }
    @include media ("<desktop") {
      font-size: 3.1rem;
      line-height: 3.5rem; }
    @include media ("<tablet") {
      font-size: 2.5rem;
      line-height: 3.5rem; }
    @media screen and (max-width: 614px) {
      font-size: 2rem;
      line-height: 3rem; }
    @media screen and (max-width: 500px) {
      font-size: 2.5rem;
      line-height: 3.2rem; }
    @include media ("<=tiny") {
      font-size: 2rem;
      line-height: 2.7rem; }

    span {
      @media screen and (max-width: 500px) {
        @include gbackground (350px);
        span {
          &::after {
            white-space: break-spaces;
            content: "\A"; } } }
      @include media ("<tiny") {
        @include gbackground (250px); } } }

  &__title-line {
    @include gbackground (1130px);
    &::after {
        white-space: break-spaces;
        content: "\A";
        @media screen and (max-width: 500px) {
          display: none; } }
    padding: 0 15px;
    @include media ("<desktop") {
      @include gbackground (900px); }
    @include media ("<tablet") {
      @include gbackground (600px);
      padding: 5px; }
    @media screen and (max-width: 614px) {
      @include gbackground (500px); }
    @media screen and (max-width: 500px) {
      padding: 0;
      span {
        padding: 5px; } } }

  &__title-pitch {
    display: flex;
    position: relative;
    margin: 58px 0;
    font-size: 1.8rem;
    @include media ("<desktop") {
      font-size: 1.5rem; }
    @include media ("<tablet") {
      margin: 35px 0; }
    @include media ("<=tiny") {
      font-size: 1rem;
      font-weight: 600;
      color: $white;
      line-height: 1.4rem;
      p {
        margin: 0 35px 0 0; }
      .landing-1__crossed-text {
        &:after {
          height: 1px;
          background-color: $white; } } } }

  // title-pitch children
  &__title-pitch--wrapper {
    width: 100%;
    z-index: 1;
    @include media ("<desktop" and ">=tablet") {
      margin-bottom: 100px; } }

  &__pitch-topics {
    margin-bottom: 69px;
    @include media ("<desktop") {
      margin-bottom: 30px; } }

  &__crossed-text {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      height: 2px;
      width: 105%;
      background-color: $text; } }


  &__pitch-price {
    margin-bottom: 108px;
    @include media ("<desktop") {
      margin-bottom: 70px; }
    @include media ("<tablet") {
      margin-bottom: 30px;
      p {
        display: inline; } }
    span {
      font-size: 3.6rem;
      // line-height: 2.0rem
      @include media ("<large") {
        font-size: 2.6rem; }
      // @include media ("<tablet")
 }      //   font-size: 1.9rem
    .landing-1__crossed-text {
      font-size: 1.8rem;
      margin-right: 15px; }
    @include media ("<tablet") {
      display: none; } }

  &__pitch-img {
    position: absolute;
    right: -90px;
    top: 114px;
    @include media ("<huge") {
      width: 795px;
      right: -40px; }
    @include media ("<desktop") {
      width: 700px;
      right: -50px; }
    @include media ("<tablet") {
      position: static;
      width: 100%;
      max-width: 425px;
      display: block;
      margin: 0 auto; }
    @include media ("<=tiny") {
      width: 120%;
      margin-left: -55px; } }

  &__pitch-button {
    position: relative;
    display: flex;
    font-size: 1.8rem;
    width: 396px;
    padding: 20px; // 24px
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
    @include media ("<desktop") {
      font-size: 1.5rem;
      width: 288px;
      padding: 14px; } }
  //   @include media ("<tablet")
  //     // margin: 300px auto
  //     margin: 0 auto
  //     max-width: 396px
  //   @include media ("<=tiny")
  //     width: 100%
  //     font-size: 1.3rem
  // // end of title-pitch children

  .btn:not(.catalog-form__btn) {
    @include media ("<tablet") {
      display: none; } }

  &__price-block {
    @include gbackground (100%);
    display: inline-block;
    color: $white;
    padding: 3px 7px;
    text-transform: none;
    font-weight: 900; }

  &__subtitle {
    @include subtitle(3.6rem);
    font-weight: 800;
    letter-spacing: 0.2rem;
    @include media ("<large") {
      font-size: 3rem;
      letter-spacing: 0; }
    @include media ("<tablet") {
      font-size: 2.2rem;
      line-height: 3rem; }
    &:not(.landing-1__promo-title) {
      margin-bottom: 75px;
      @include media ("<tablet") {
        margin-bottom: 50px; } } }


  // 2ND SECTION
  &__orb-list-1 {
    .orb-list__wrap {
      flex-wrap: wrap;
      @include media ("<desktop") {
        justify-content: center; }

      .orb-list__item {
        width: 175px;
        @include media ("<desktop") {
          flex-basis: 33%;
          span {
            text-align: center; } }
        @include media ("<tablet") {
          flex-basis: 50%; }
        @include media ("<=tiny") {
          max-width: 150px;
          span {
            margin-top: -20px; }
          .orb-list__svgs {
            width: 95px; }
          .orb-list__svg-bg {
            width: 100%; }
          .orb-list__svg-img {
            width: 45px; } }

        &:nth-child(1) {
          span {
            @include media ("<=tiny") {
              margin-top: -10px; } } }

        &:nth-child(2) {
          span {
            margin-left: 30px;
            max-width: 200px;
            @include media ("<desktop") {
              margin: 0; }
            @include media ("<=tiny") {
              margin-top: -25px; } } }

        &:nth-child(3) {
          .orb-list__svgs {
            @include media ("<=tiny") {
              width: 100px; } } }

        // &:nth-child(5)
        //// @include media ("<desktop")
        ////   flex-basis: 100%
        //     span
 } } }        //       text-align: center

  // 3RD SECTION
  .tabs {
    width: 100%;
    @include media ("<large") {
      margin: 50px auto 50px; }
    @include media ("<tablet") {
      width: calc(100% + 30px);
      margin: 30px -15px 30px; } }

  .tabs__buttons {
    width: 973px;
    @include media ("<desktop") {
      display: none; } }

  .tabs__text-area {
    overflow: hidden;
    // display: flex
 }    // justify-content: center

  .tabs__text-wrap {
    @include media("<desktop") {
      position: static;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity $transition, max-height $transition;
      p {
        font-size: 1.4rem;
        line-height: 2.0rem; }

      &--active {
        padding: 20px;
        max-height: 1500px;
        border-bottom: 2px solid;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
        border-image-slice: 1;
        transition: opacity $transition, max-height $transition*5; } }

    .landing-1__swiper-img {
      width: 100%;
      max-width: 185px;
      margin: 0 auto;
      @include media ("<desktop") {
        max-height: 150px;
        object-fit: contain; } }
    .swiper-wrapper {
      max-height: 100%;
      height: unset; }

    .swiper-slide {
      width: 185px;
      height: 185px;
      display: flex;
      align-items: center;

 } }      // padding: 5px // check if necessary

  .tabs-swiper-container {
    width: 100%;
    margin: 100px 0;
    @include media ("<desktop") {
      margin: 0; }
    // &::before
    //   content: ""
    //   background-image: linear-gradient(90deg, $bg-color, rgba(255, 255, 255, 0.001))
    //   position: absolute
    //   left: 0
    //   width: 36px
 }    //   height: 100%

  .swiper-wrapper {
    // z-index: 0
    // margin-bottom: 30px
    // &::before
    //   content: ""
    //   background-image: linear-gradient(90deg, $bg-color, rgba(255, 255, 255, 0.001))
    //   position: absolute
    //   left: 0
    //   width: 36px
 }    //   height: 100%


  .swiper-button-next, .swiper-button-prev {
    border: 1px solid;
    border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
    border-image-slice: 1;
    width: 33px;
    height: 86px;
    margin-top: -43px;
    background: $bg-color;
    z-index: 1;
    @include media ("<desktop") {
      display: none; }
    &:hover {
      background: #e5edff;
      transition: 400ms; }
    svg {
      fill: url(#linear-grad-4); }
    &::after {
      display: none; } }

  .swiper-button-next {
    transform: rotate(180deg);
    right: 1px; }

  .swiper-button-prev {
    left: 0; }

  .swiper-pagination {
    width: 90%;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    @include media (">=desktop") {
      display: none; }
    .swiper-pagination-bullet {
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
      background-color: $text;
      border-radius: unset;
      &:focus {
        outline: none; }
      &::after {
        content: " ";
        padding: 15px;
        display: block;
        position: relative;
        bottom: 13px; }
      &-active {
        @include gbackground(100%); } } }


  .tabs__tab-button-mob {
    @include media("<desktop") {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px 10px;
      border-bottom: 2px solid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-image-source: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);
      border-image-slice: 1;
      &:hover {
        background-position: 100% 0; }
      span {
        margin-left: auto;
        &:hover {
          background-position: 100% 0; } }
      svg {
        fill: url(#linear-grad-2);
        margin-left: auto;
        transition: transform $transition; } } }


  // 4TH SECTION
  &__orb-list-2 {
    .orb-list__item {
      width: 303px;
      flex-basis: 33%;
      @include media ("<tablet") {
        flex-basis: 50%;
        max-width: 185px; }
      @include media ("<=tiny") {
        max-width: 144px; }
      span {
        max-width: 280px;
        @include media ("<tablet") {
          text-align: center; }
        @include media ("<=tiny") {
          line-height: 2rem;
          margin-top: -10px; } } }

    .orb-list__wrap {
      flex-wrap: wrap;
      > div:nth-of-type(3) {
        .orb-list__svgs {
          @include media ("<=tiny") {
            width: 110px; } }
        .orb-list__svg-bg {
          @include media ("<=tiny") {
            width: 110px; } }
        .orb-list__svg-img {
          margin-left: -20px;
          @include media ("<=tiny") {
            width: 60px; } } }
      > div:nth-of-type(6) {
        .orb-list__svgs {
          @include media ("<=tiny") {
            width: 95px; } }
        .orb-list__svg-bg {
          @include media ("<=tiny") {
            width: 95px; } }
        .orb-list__svg-img {
          margin-top: 10px;
          @include media ("<=tiny") {
            width: 60px; } }
        span {
          max-width: 303px; } } }

    .orb-list__svgs {
      @include media ("<=tiny") {
        width: 105px; } }

    .orb-list__svg-bg {
      @include media ("<=tiny") {
        width: 105px; } }

    .orb-list__svg-img {
      @include media ("<=tiny") {
        width: 50px; } } }


  // 5TH SECTION
  &__promo {
    position: relative;
    margin-bottom: 135px;
    @include media ("<tablet") {
      margin: 0; }

    > div { // promo-rows
      display: flex;
      @include media ("<tablet") {
        display: block; } } }

  .svg-grad-big-arrow {
    right: 0; }

  &__promo-row--1 {
    justify-content: space-between; }

  &__promo-row--2 {
    justify-content: space-around;
    position: relative;
    left: -20px;
    margin-top: 105px;
    @include media ("<desktop") {
      margin-top: 65px; }
    @include media ("<tablet") {
      margin-top: 40px;
      left: 0; } }

  &__promo {
    .landing-1__promo-item {
      position: relative;
      display: flex;
      @include media ("<tablet") {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 70px; }
      @include media ("<=tiny") {
        display: block;
        margin-bottom: 60px;
        position: static; }
      > .landing-1__promo-item-img {
        object-fit: contain;
        @include media ("<=tiny") {
          position: static;
          display: block; } }
      > .landing-1__promo-item-description {
        display: flex;
        flex-flow: column;
        @include media ("<=tiny") {
          display: block;
          text-align: center;
          margin: 20px auto 0; }
        > span {
          &:first-child {
            color: $white;
            font-size: 24rem;
            font-weight: 900;
            line-height: 19rem;
            @include media ("<desktop") {
              font-size: 16.8rem;
              line-height: 13.3rem; }
            @include media ("<=tiny") {
              display: none; } }
          &:not(first-child) {
            color: $text;
            font-size: 1.8rem;
            font-weight: 800;
            @include media ("<desktop") {
              font-size: 1.5rem; } } } } } }

  &__promo-item-img-wrapper {
    position: relative;
    @include media ("<=tiny") {
      position: static;
      margin: 0 auto; } }

  &__promo-item-bg {
    position: absolute;
    top: -100px;
    z-index: -1;
    left: -140px;
    width: 534px;
    img {
      width: 100%; }
    @include media ("<desktop") {
      width: 393px;
      // width: 365.2px
 }      // height: 538.3px
    @include media ("<tablet") {
      left: -160px;
      transform: translateX(-50%);
      left: 50%; }
    @include media ("<=tiny") {
      top: -195px; } }

  &__promo-item--1 {
    @include media ("<huge") {
      left: 115px; }
    @include media ("<tablet") {
      margin-left: 30px; }
    @include media ("<=tiny") {
      margin-left: 0; }
    .landing-1__promo-item-img {
      position: relative;
      top: 155px;
      left: -7px;
      width: 232px;
      // height: 234px
      @include media ("<desktop") {
        width: 171px;
        // height: 163.8px
        left: 0;
        top: 0; // 186px
        margin-top: 95px;
        margin-left: -30px; } // 15px
      @include media ("<=tiny") {
        position: static;
        display: block;
        margin: 60px auto 20px; } }
    .landing-1__promo-item-description {
      // margin-left: -280px
      margin-top: 125px;
      width: 150px;
      @include media ("<desktop") {
        margin-top: 95px; } } }

  &__promo-item--2 {
    margin-top: 87px; // top: 87px
    left: -127px;
    @include media ("<large") {
      left: 0; }
    .landing-1__promo-item-img {
      width: 341px;
      @include media ("<=tiny") {
        transform: translateX(-50%);
        margin: 0 50%; } }
    .landing-1__promo-item-description {
      max-width: 190px;
      margin-left: -65px;
      margin-top: -30px;
      > span > span {
        text-transform: none; }
      @include media ("<desktop") {
        margin-left: -75px;
        max-width: 158px;
 } } }        // width: 150px

  &__promo-item--3 {
    @include media ("<huge") {
      left: 115px; }
    @include media ("<large") {
      left: 0; }
    @include media ("<desktop") {
      margin-left: 50px; } // 70px
    @include media ("<tablet") {
      margin-left: -15px; }
    .landing-1__promo-item-img {
      width: 354px;
      // height: 247px
      @include media ("<desktop") {
        width: 306px; }
      @include media ("<=tiny") {
        margin: 0 auto; } }
    .landing-1__promo-item-description {
      // width: 165px
      max-width: 140px;
      margin-left: -28px;
      margin-top: -23px;
      @include media ("<desktop") {
        margin: auto 0 auto -45px; } } }

  &__promo-item--4 {
    top: -67px;
    left: 10px;
    @include media ("<desktop") {
      left: 0; }
    @include media ("<tablet") {
      // margin-top: 30px
      margin-left: 0; }
    .landing-1__promo-item-img {
      width: 318px;
      z-index: 0;
      @include media ("<desktop") {
        width: 249px; }
      @include media ("<=tiny") {
        margin: 0 auto; } }
    .landing-1__promo-item-description {
      width: 185px;
      margin-top: 40px;
      margin-left: -30px;
      @include media ("<desktop") {
        // margin-left: 0
        margin-top: 60px;
        width: 150px; }
      @include media ("<tablet") {
        margin-top: -25px;
        margin-left: 0; } } }

  &__goto-catalog {
    font-size: 1.8rem;
    padding: 15px;
    max-width: 584px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    span {
      margin-right: 10px; }
    @include media ("<desktop") {
      font-size: 1.5rem; }
    // @include media ("<=tiny")
 }    //   font-size: 1.3rem


  // 6TH SECTION
  &__partners-section {
    .partners-swiper-container {
      overflow: hidden;
      position: relative;
      margin: 100px 0;
      margin-left: -100px;
      padding-left: 100px;
      @include media ("<desktop") {
        margin: 0;
        padding-bottom: 90px;
        padding-left: unset; }
      @include media ("<tablet") {
        margin-top: 60px; }
      @include media ("<=tiny") {
        padding-bottom: 50px; }

      .swiper-wrapper {
        height: unset;
        max-height: 100%; } }

    .swiper-slide {
      display: flex; }

    .landing-1__swiper-img--wrapper {
      display: block;
      width: 272px;
      height: 272px;
      flex-shrink: 0;
      margin-right: 55px;
      @include media ("<desktop") {
        margin-right: 30px; }
      @include media ("<tablet") {
        position: static;
        width: 100px;
        height: auto;
        margin: 0 20px 0 0; }
      @include media ("<=tiny") {
        width: 58px;
        height: auto; } }

    .landing-1__swiper-img {
      width: 100%; }

    .landing-1__swiper-text {
      display: block;
      @include media ("<tablet") {
        max-width: 500px;
        margin: 0; }

      h3 {
        color: $text;
        font-size: 2.4rem;
        margin: 0;
        font-weight: 800;
        @include media ("<huge") {
          font-size: 2rem; }
        @include media ("<tablet") {
          font-size: 1.8rem; }
        @include media ("<=tiny") {
          font-size: 1.6rem;
          line-height: 2rem; } }

      span {
        color: $text;
        font-size: 1.8rem;
        @include media ("<huge") {
          font-size: 1.5rem; }
        @include media ("<tablet") {
          font-size: 1.4rem; }
        @include media ("<=tiny") {
          font-size: 1.2rem;
          line-height: 1.5rem; } }

      p {
        padding: 50px 140px 0 0px;
        margin: 0;
        font-size: 1.8rem;
        line-height: 3.0rem;
        text-transform: none;
        text-align: justify;
        @include media ("<huge") {
          font-size: 1.5rem;
          line-height: 2.8rem; }
        @include media ("<desktop") {
          padding: 30px 25px 0 0; }
        @include media ("<tablet") {
          padding: 0;
          text-align: left;
          font-size: 1.4rem;
          margin-top: 80px;
          margin-left: -120px;
 }          // max-width: 500px
        @include media ("<=tiny") {
          margin-top: 40px;
          margin-left: -78px;
          line-height: 2rem; } } }

    &__form-section {
      @include media("<tablet") {
        margin-bottom: 50px; } }

    .swiper-button-prev {
      left: 25px; }

    .swiper-pagination {
      width: 105%;
      margin-top: 70px;
      @include media ("<tablet") {
        margin-top: 40px; }
      @include media ("<=tiny") {
        margin-top: 30px; } } } }

