.form {
  text-transform: uppercase;
  display: flex;
  flex-flow: column;

  &__input {
    display: block;
    padding: 15px 10px;
    border: none;
    opacity: 0.9;
    text-transform: uppercase;
    outline-color: $gradient-end;
    &::placeholder {
      text-transform: uppercase;
      font-weight: 600;
      opacity: 0.5; }
    &--error {
      outline: 1px solid $main-link;
      color: $main-link;
      &::placeholder {
        color: $main-link; } } }

  .wpcf7-not-valid {
    outline: 1px solid $main-link;
    color: $main-link;
    &::placeholder {
      color: $main-link; } }

  .wpcf7-form-control-wrap {
    margin-top: 15px; }



  &__btn {
    appearance: none;
    border-radius: 0;
    text-align: center;
    border: 2px solid $btn;
    transition: background-color $transition, color $transition, outline $transition; }

  &__cbx-label {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      padding-left: 15px; } }

  &__checkbox {
    appearance: none;
    cursor: pointer;
    position: relative;
    height: 22px;
    width: 22px;
    padding: 0;
    background: #A9A9A9;
    margin: 0;
    outline-color: $gradient-end;
    transition: outline $transition;
    &:checked {
      ~ svg {
        opacity: 1; } } }

  &__checkmark {
    position: absolute;
    width: 22px;
    height: 22px;
    opacity: 0;
    transition: opacity $transition;
    fill: $white; } }
