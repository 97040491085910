.tymodal {
  background-image: url('../img/cbm-bg.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding: 30px;

  &__brand {
    font-size: 1.8rem;
    font-weight: 800;
    color: $black;
    text-align: center;
    display: flex;
    align-items: flex-start;
    @include media ("<tablet") {
      margin: 0; } }
  &-thanks {
    &__wrapper {
      position: absolute;
      top: 30%;
      text-transform: uppercase;
      width: 640px;
      opacity: 1;
      @include media ("<tablet") {
        width: 265px; } }

    &__title {
      span {
        @include gbackground(640px);
        color: $white;
        font-size: 7.2rem;
        font-weight: 800;
        margin: 0;
        padding: 0 20px;
        @include media ("<tablet") {
          @include gbackground(265px);
          font-size: 3rem;
          padding: 0 10px; } } }

    &__subtitle {
      color: $text;
      font-size: 1.8rem;
      font-weight: 600;
      @include media ("<tablet") {
        font-size: 1.4rem;
        color: $white; } } } }
